import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import {CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule,  } from '@angular/forms';
import {RouterModule} from '@angular/router';
import { A11yModule } from '@angular/cdk/a11y';
import {BidiModule} from '@angular/cdk/bidi';
import {ObserversModule} from '@angular/cdk/observers';
import {OverlayModule} from '@angular/cdk/overlay';
import {PlatformModule} from '@angular/cdk/platform';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { CarouselModule } from 'ngx-bootstrap/carousel';


//material//
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle'
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatToolbarModule} from '@angular/material/toolbar'
 
  



import { HomeComponent } from './home/home.component';


import {EmpregistrationComponent} from './empregistration/empregistration.component';
import { Owner_Registration_SaverComponent } from "./mainlog/owner_registration_saver/owner_registration_saver.component";
import { MainNavComponent } from "./main-nav/main-nav.component";


import { ProductsComponent } from './products/products.component';
import { WhyusComponent } from './whyus/whyus.component';


import { PriceComponent } from './price/price.component';
import { SuccessregisterComponent } from './successregister/successregister.component';

import { EmpidvalidatorDirective } from './validators/empidvalidator.directive';
import { FnameValidatorDirective } from './validators/fname-validator.directive';
import { EmailValidatorDirective } from './validators/email-validator.directive';
import { FooterComponent } from './footer/footer.component';




import { PasswordValidatorDirective } from './validators/password-validator.directive';
import { OpulenceComponent } from './opulence/opulence.component';


import { CompareValidatorDirective } from './validators/compare-validator.directive';

import { StartEndDatevalidatorDirective } from './validators/start-end-datevalidator.directive';
import { ServicesSolnComponent } from './services-soln/services-soln.component';
import { ContactUSComponent } from './contact-us/contact-us.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { OurCompanyComponent } from './our-company/our-company.component';
import { MedicalServicesComponent } from './medical-services/medical-services.component';
import { NewProductDevelopementComponent } from './new-product-developement/new-product-developement.component';
import { AiMLComponent } from './ai-ml/ai-ml.component';
import { IotComponent } from './iot/iot.component';
import { NewsUpdateComponent } from './news-update/news-update.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { AboutusComponent } from './aboutus/aboutus.component';

import {AngularFireModule} from '@angular/fire'
import {AngularFirestoreModule} from '@angular/fire/firestore'
import { environment } from 'src/environments/environment';
import { AgroticsProductComponent } from './agrotics-product/agrotics-product.component';
import { SmartFenceProductComponent } from './smart-fence-product/smart-fence-product.component';
import { SmartSantizerProductComponent } from './smart-santizer-product/smart-santizer-product.component';
import { ElephantRepellentSystemProductComponent } from './elephant-repellent-system-product/elephant-repellent-system-product.component';

  
@NgModule({
  declarations: [
    AppComponent,
    
      
    HomeComponent,
    Owner_Registration_SaverComponent,
    MainNavComponent,
    ProductsComponent,
    WhyusComponent,
    OpulenceComponent,
    PriceComponent,
    SuccessregisterComponent,
   
 
    EmpidvalidatorDirective,
    FnameValidatorDirective,
    EmailValidatorDirective,
    FooterComponent,
    PasswordValidatorDirective,
    CompareValidatorDirective,
    
    StartEndDatevalidatorDirective,
    EmpregistrationComponent,
    ServicesSolnComponent,
    ContactUSComponent,
    OurTeamComponent,
    OurCompanyComponent,
    MedicalServicesComponent,
    NewProductDevelopementComponent,
    AiMLComponent,
    IotComponent,
    NewsUpdateComponent,
    PrivacyComponent,
    TermsConditionsComponent,
    AboutusComponent,
    AgroticsProductComponent,
    SmartFenceProductComponent,
    SmartSantizerProductComponent,
    ElephantRepellentSystemProductComponent,  
  ],
  imports: [
    ObserversModule,
    FlexLayoutModule,
    CommonModule,
    A11yModule,
    ScrollingModule,
    BrowserAnimationsModule,
    PlatformModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule,
    OverlayModule,
    AppRoutingModule,
   
    // Material
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatRadioModule,
    MatListModule,
    MatMenuModule,
    
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
   
    MatSnackBarModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSortModule,
    MatPaginatorModule,
    CarouselModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
