<!-- 
@File : privacy.component.html
@Time : 2020/06/0809:22:10
@Author : Supriya Suresh
@Version: 1.0
@Contact: supriya@melodale.com
@License: (c)Copyright 2020-2021, Melodale
@Desc : None 
-->

<div class="pc000">
    <h5 class="mdfnt02 pc001">Melodale Technology Solutions Privacy Policy</h5>
    <div class="pc002 overFlowSection">
        <div class="overSubFlowSection">
            <span class="mdfnt00">Effective Date: </span>
            <span>June 30, 2020</span>
            <p class="pc002a">When you provide Melodale with your information, we work hard to protect your information.
                This Privacy Policy is meant to help you understand what information we collect, why we
                collect it and how you may be able to update, manage and/or request that we delete your
                information.Please read this Privacy Policy carefully.By continuing to interact with our
                sites, you acknowledge that you have read and understand the terms described in this Privacy
                Policy.
            </p>
            <p class="pc002aa">This Privacy Policy applies to information collected by Melodale, any
                affiliated or subsidiary companies (“Melodale.com”,”we”,”our”,”us”), offline or online,
                including, but not limited to all websites or apps that post a link to this Privacy Policy
                (collectively, “the Sites”).
            </p>
            <p class="pc002a">
                <span class="mdfnt00">How does Melodale collect my personal information?</span>
            </p>
            <p class="pc002aa">Melodale collects and processespersonal information for legitimate business purposes in order
                to, for example, process product expiry information, communicate with existing customers about products and
                services, and/or address concerns related to purchased products or services. We collect your personal
                information, for
                example, when you:
            </p>
            <ul class="pc002b">
                <li>Browse our websites.</li>
                <li>Purchase a product or sign-up for a mailing list.</li>
                <li>Report product/service issues and/or complete customer surveys.</li>
                <li>Provide account information or give us your contact information.</li>
                <li>Participate in surveys or contests.</li>
                <li>Provide information to authenticate your access through the sites, such as username and/or passwords;
                    and/or</li>
                <li>Correspond with us directly.</li>
            </ul>
            <p class="pc002a">
                <span class="mdfnt00">What does Melodale collect?</span>
            </p>
            <p class="pc002aa">Melodale collects and processes customers’ personal information for legitimate business
                purposes. The types
                of personal information we collect and/or share depend on the product and/or service you have with us.This
                information may
                include:
            </p>
            <ul class="pc002b">
                <li>Your name and contact information.</li>
                <li>Your past purchase and transaction history.</li>
                <li>Identifiers such as real name, alias, postal address, unique personal identifier, online identifier, IP
                    address,
                    email address or other similar identifiers.</li>
                <li>Personal information, such as your age, date of birth.</li>
                <li>Data obtained through Analytic tools (e.g. Google Analytics): We use analytics toolsto collect data
                    about the
                    use of our Sites and Service. Analytics tools collect data such as how often Users and Visitors visit or
                    use the
                    Sites or Service, which pages they visit and when, and which website, ad or e-mail message brought them
                    there.</li>
            </ul>
            <p class="pc002aa">Visitors can always refuse to supply personally identification information, except that it
                may prevent them from engaging in certain Site related activities
            </p>
            <p class="pc002a">
                <span class="mdfnt00">Why does Melodale collect my personal information?</span>
            </p>
            <p class="pc002aa">Melodale may collect and use Visitors personal information for the following purposes:</p>
            <ul class="pc002b">
                <li>To gain a better understanding on how Visitors and Users evaluate, use and interact with our
                    Service, and how we could improve their and others’ user experience, and continue improving our
                    products, offerings and the overall performance of our Service.
                </li>
                <li>To contact our Visitors, Users and Customers (whether existing or prospective) with general
                    or personalized service-related messages, as well as promotional messages that may be of
                    specific interest to them
                </li>
                <li>To provide customer service and technical support.</li>
                <li>To initiate Sales call.</li>
                <li>To protect and secure our Users , Customers, Visitors, ourselves and our service.</li>
            </ul>
            <p class="pc002a">
                <span class="mdfnt00">Information We Automatically Collect</span>
            </p>
            <p class="pc002aa">We collect certain information by automated means when you visit our Sites, such as how many
                users visited our Sites and the pages accessed. By collecting this information, we learn how to best tailor
                our Sites to our visitors. We collect this information through various means such as “cookies” and “web
                beacons.” In addition, we may collect IP addresses, as explained below.For more information regarding our
                use of cookies, please refer to our separate Cookie Policy.
            </p>
            <p class="pc002aa">
                <span class="mdfnt00">Cookies. </span> As described in our separate Cookie Policy, like many
                companies, we use “cookies” on some of our Sites. Cookies are pieces of code placed on your devices when you
                visit certain websites. We use cookies to tell us, for example, whether you have visited us before or if you
                are a new visitor and to help us identify features of the Sites in which you may have the greatest interest.
                Cookies may enhance your online experience by saving your preferences while you are visiting a particular
                website.
            </p>
            <p class="pc002aa">Most web browsers will tell you how to stop accepting new cookies, how to be notified when
                you receive a new cookie, and how to disable existing cookies. Please note, however, that without cookies
                you may not be able to take full advantage of all the features and functionality of the Sites.
            </p>
            <p class="pc002aa">
                <span class="mdfnt00">Web Beacons. </span> As described in our separate Cookie Policy,
                certain pages on our website contain “web beacons” (also known as Internet tags, pixel tags and clear GIFs).
                These web beacons allow third parties to obtain information such as the IP address of the computer that
                downloaded the page on which the beacon appears; the URL of the page on which the beacon appears; the time
                the page containing the beacon was viewed; the type of browser used to view the page; and the information in
                cookies set by the third party.
            </p>
            <p class="pc002a">
                <span class="mdfnt00">How We Protect Your Information
                </span>
            </p>
            <p class="pc002aa">We work hard to protect you and Melodale from unauthorized access, alteration, disclosure or
                destruction of information we hold. To that end, we maintain administrative, technical, and physical
                safeguards to protect against loss, misuse or unauthorized access, disclosure, alteration or destruction of
                the information you provide when visiting or using the Sites.
            </p>
            <p class="pc002a">
                <span class="mdfnt00">Your Rights to your Information
                </span>
            </p>
            <p class="pc002aa">If you are a resident of the EU, you have the right to request access to the personal
                information we have collected about you for the purposes of reviewing, modifying, or requesting erasure of
                the information as per
                <span class="mdfnt00">GDPR Data Protection law.
                </span>
                You also have the right to
                request a copy of the information we have collected about you.In certain situations, you may also request
                that we restrict or cease processing or sharing your information.
            </p>
            <p class="pc002aa">If you would like to make a request to access, review, or correct the personal information we
                have collected about you, please contact us at
                <a href="mailto:info@melodale.com" class="pc003">info@melodale.com
                </a>.
            </p>
            <p class="pc002aa">If you are a resident of Nevada, you have the right to request that we restrict or cease the
                transfer of your information to third parties in some situations.If you would like to make a request to
                restrict the sharing of your information, please contact us at
                <a href="mailto:info@melodale.com" class="pc003">info@melodale.com
                </a>.
            </p>
            <p class="pc002aa">To help protect your privacy and security, we will take reasonable steps to verify your
                identity before granting access to your information.
            </p>
            <ul class="pc002b">
                <li><span class="mdfnt00">California Resident Access Rights (CCPA) </span> : If you are a California
                    resident, beginning on January 1, 2020, you have the rights described below regarding your personal
                    information:</li>
            </ul>
            <p class="pc002aa">Access to Specific Information and Data Portability: You have the right to request that
                we disclose certain information to you about our collection and use of your personal information over
                the past 12 months. Once we receive and confirm your personal information request, we will disclose to
                you, based on your request: </p>
            <ul class="pc002b">
                <li>The categories and specific pieces of personal information we collected about you.</li>
                <li>The categories of sources for the personal information we collected about you. </li>
                <li>Our business or commercial purpose for collecting or selling that personal information.</li>
                <li>The categories of third parties with whom we share that personal information; and</li>
                <li>If we sold or disclosed your personal information for a business purpose, two separate lists
                    identifying: (a) the categories of your personal information collected, and the categories of the
                    third parties to whom; and (b) the categories of your personal information that was disclosed for
                    business purposes. </li>
            </ul>
            <p class="pc002aa">We will not, however, disclose any of the following information in response to an access
                request: your social security number, driver’s license number or other government-issued identification
                number, financial account number, any health or medical identification number, an account password, or
                security questions or answers in response to a request to know. </p>
            <p class="pc002aa">You have the right to request that we delete the personal information that we collected
                from you and retained, subject to certain exceptions. Once we receive and confirm your personal
                information request and verify your identity through our two tier verification process, we will delete
                your personal information to the extent required by law and unless permitted or required by law to
                retain your personal information. </p>
            <p class="pc002aa">We may deny your deletion request if retaining the information is necessary for us or our
                service providers to:
            </p>
            <ul class="pc002b">
                <li>Complete the transaction for which we collected the personal information, provide a good or service
                    that you requested, take actions reasonably anticipated within the context of our ongoing business
                    relationship with you, or otherwise perform our contract with you. </li>
                <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or
                    prosecute those responsible for such activities. </li>
                <li>Debug products to identify and repair errors that impair existing intended functionality. </li>
                <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or
                    exercise another right provided for by law. </li>
                <li>Comply with California Penal Code Section 1546 et seq. (the California Electronic Communications
                    Privacy Act). </li>
                <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public
                    interest that adheres to all other applicable ethics and privacy laws, when the information's
                    deletion may likely render impossible or seriously impair the research's achievement, if you
                    previously provided informed consent. </li>
                <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your
                    relationship with us. </li>
                <li>Comply with a legal obligation; or</li>
                <li>Make other internal and lawful uses of that information that are compatible with the context in
                    which you provided it. </li>
            </ul>
            <p class="pc002aa">We do not sell your personal information to third parties for a profit; however, the
                information is shared as described in this Policy. Should you wish to opt-out of this exchange, or not
                be contacted for marketing purposes.
            </p>
            <p class="pc002a">
                <span class="mdfnt00">RETENTION OF YOUR INFORMATION
                </span>
            </p>
            <p class="pc002aa">We will keep the personal information we collected from our Visitors for the following
                period of time and we will safely erase the information after that:
            </p>
            <ul class="pc002b">
                <li>Past Clients: 7 years after the last contract expiration date. </li>
                <li>Visitors without opt-in or who have revoked their opt-in: 1 year after last lead interaction. </li>
                <li>Visitors who have asked to be removed from our database: 30 days after the request. </li>
            </ul>
            <p class="pc002a">
                <span class="mdfnt00">Children’s Privacy
                </span>
            </p>
            <p class="pc002aa">Our Sites are general audience Sites not directed at children under the age of 16. If we
                obtain actual knowledge that any information we collect has been provided by a child under the age of
                16, we will promptly delete that information.
            </p>
            <p class="pc002a">
                <span class="mdfnt00">How to Contact US
                </span>
            </p>
            <p class="pc002aa">If you have any questions or comments about this Privacy Policy, please contact:
            </p>
            <p class="pc002a">
                <span class="mdfnt00">MELODALE TECHNOLOGY SOLUTIONS
                </span>
            </p>
            <p class="pc002aa">Phone:
            </p>
            <p class="pc002aa">Email:
                <a href="mailto:info@melodale.com" class="pc003">info@melodale.com
                </a>.
            </p>
            <p class="pc002a">
                <span class="mdfnt00">Updates to Our Privacy Policy
                </span>
            </p>
            <p class="pc002aa">This Privacy Policy may be updated periodically. We may modify this Privacy Policy at any
                time in our sole discretion. If we make material changes to this Privacy Policy that increase our rights
                to use personal information we have previously collected about you, we will obtain your consent either
                through an email to your registered email address or by prominent posting on our sites.If we intend to
                further process your personal data for a purpose other than that for which the personal data were
                collected, prior to that further processing, Melodale shall provide you with information about that
                other purpose and with additional information necessary to ensure fair and transparent processing.
            </p>
        </div>
    </div>
</div>