
// @File : medical-services.component.ts
// @Time : 2020/07/04 14:22:10
// @Author : Supriya Suresh
// @Version: 1.0
// @Contact: supriya@melodale.com
// @License: (c)Copyright 2020-2021, Melodale
// @Desc : None 

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-services',
  templateUrl: './medical-services.component.html',
  styleUrls: ['./medical-services.component.scss',
  './../app.component.scss']
})
export class MedicalServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
