import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-update',
  templateUrl: './news-update.component.html',
  styleUrls: ['./news-update.component.scss']
})
export class NewsUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
