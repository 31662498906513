<div class="au000">
    <div class="au001">
            <div class="au001b">
                <h1 class="au001c">About Us</h1>
                <h3 class="au001cc">Group of self-motivated people 
                    <br>striving towards one goal - <span class="au001cd">EXCELLENCE</span>
                </h3>
            </div>
    </div>
    <div class="au002">
        <mat-card class="au002a">
            <mat-card-header class="au002a1">
                <mat-card-title class="au002a2">Our<br>Mission<br>& Vision</mat-card-title>
            </mat-card-header>
            <mat-card-content class="au002a3">
                <p>Digitize, organize and empower information using latest 
                technologies and user-friendly solutions</p>
     
                <p class="au002bb">We see that proper utilization of current technologies, that are possible to enhance 
                    our technical challenges to overcome and Augment the business needs. We do progressive 
                    approach, focusing on appropriate ideas we can take today, to develop breakthrough 
                    business, improve and lower costs for all requirements with latest technologies.</p>
           
                <p class="au002bb mdc000 mdfnt01 au002a4">
                    <span class="au002ba">❝</span> 
                    Leaping towards the goal to become a cross market hub for 
                    all IT Services and Solutions. 
                    <span class="au002ba">❞</span>
                </p>
            </mat-card-content>
        </mat-card>
        <mat-card class="au002a mdbkImg01">
            <mat-card-header class="mdc002 au002a2 au002a5">
                Our Values
            </mat-card-header>
            <mat-card-content>
                <mat-list>
                    <mat-list-item>
                      <mat-icon mat-list-icon class="au002a7">done_all</mat-icon>
                      <div mat-line class="au002a8">We listen our Customers</div>
                      <div mat-line class="au002a6">
                        An effective and transparent communication is the soul of any successful project. 
                        Likewise, we listen, understand and analyze customers problem statements closely 
                        before providing a solution.
                   </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                      <mat-list-item>
                      <mat-icon mat-list-icon class="au002a7">done_all</mat-icon>
                      <div mat-line class="au002a8">We Face the Challenge</div>
                      <div mat-line class="au002a6">
                        Facing the challenges and using them as an oppurtunity to strive towards excellence 
                        is a trait out team follows. Our experts team looks at each problem statements, study, 
                        analyze and brainstorm them to derive the best solution.
                      </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                      <mat-list-item>
                      <mat-icon mat-list-icon class="au002a7">done_all</mat-icon>
                      <div mat-line class="au002a8">We Think long term</div>
                      <div mat-line class="au002a6">
                        We always work towards having a long-term relationship with customers. The solutions and 
                        products we offer are always for a wider scope of verticals. This takes the relation from 
                        vertical to horizontal, so that customers can get the maximum benefit from oue offerings.
                      </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                      <mat-list-item>
                      <mat-icon mat-list-icon class="au002a7">done_all</mat-icon>
                      <div mat-line class="au002a8">We Keep it Simple</div>
                      <div mat-line class="au002a6">
                        We know, the best solutions for any problem needs to stay simple. We tackle each problem as 
                        grain of sand and hence we try and keep things simple, so that the smartest and quickest 
                        solutions are achieved.
                      </div>
                    </mat-list-item>
                  </mat-list>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="au004">
        <h2>Meet Our Team</h2>
        <div class="au004a">
            <div class="au004aa">
                <!-- V1.0 Release changes -->
                <!-- <img class="au004a1" src="../../assets/employees/Sharon.png" alt="Sharon"> -->
                <!-- <img class="au004a1" src="../../assets/employees/Pravin.png" alt="Pravin"> -->
                <!-- <img class="au004a1" src="../../assets/employees/Swathi.png" alt="Swathi"> -->
                <!-- <img class="au004a1" src="../../assets/employees/Mohanan.png" alt="Mohanan"> -->
                <!-- <img class="au004a1" src="../../assets/employees/Vikram.png" alt="Vikram"> -->
                <!-- <img class="au004a1" src="../../assets/employees/Rohith.png" alt="Rohith"> -->
                <!-- <img class="au004a1" src="../../assets/employees/Supriya.png" alt="Supriya"> -->
                <button mat-button class="au004a22 mdfnt01">
                    <b class="au004a21 mdfnt00">Sharon K B</b>
                    <br>Founder & Director</button>
                <button mat-button class="au004a22 mdfnt01">
                    <b class="au004a21 mdfnt00">Pravin U</b>
                    <br>Founder</button>
                <button mat-button class="au004a22 mdfnt01">
                    <b class="au004a21 mdfnt00">Swathi M</b>
                    <br>Founder</button>
                <button mat-button class="au004a22 mdfnt01">
                    <b class="au004a21 mdfnt00">Mohanan A</b>
                    <br>Director</button>
                    <!-- SUPRIYA CHANGES ON HOME WEBSITE
                <button mat-button class="au004a22 mdfnt01">
                    <b class="au004a21 mdfnt00">Suresh Shanmukam</b>
                    <br>Director</button> -->
                <button mat-button class="au004a22 mdfnt01">
                    <b class="au004a21 mdfnt00">Eldho Kurian</b>
                    <br>Chief Technical Officer</button>
                    <!-- SUPRIYA CHANGES ON HOME WEBSITE
                <button mat-button class="au004a22 mdfnt01">
                    <b class="au004a21 mdfnt00">Vikram Suresh</b>
                    <br>Technical Advisor</button> -->
                <button mat-button class="au004a22 mdfnt01">
                    <b class="au004a21 mdfnt00">Rohith Mohan A</b>
                    <br>IT Architectural & Technical Advisor</button>
                <button mat-button class="au004a22 mdfnt01">
                    <b class="au004a21 mdfnt00">Supriya Suresh</b>
                    <br>IT Architectural & Technical Advisor</button>
            
            </div>
        </div>
    </div>
    <div class="au11 mdht00 mdbkImg02">
        <div class="au38">
            <div class="au0038a mdfnt01 mdc003">
                <span class="au00038a mdfnt00">Join Us</span><br>
                Plan, Priotize and Track your works with a single click.
            </div>
            <div class="au39">
                <a mat-button class="mdc002 mdbkImg01" routerLink="/contactus">
                    Get Started</a>
            </div>
        </div>
    </div>

</div>


