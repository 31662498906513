import { Component, OnInit, OnDestroy } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'MDPPM';
  mediaSub : Subscription;
  deviceXs : boolean;
  deviceSm : boolean;
  deviceItsm : boolean;
  deviceLg : boolean;
  constructor(public mediaObserver:MediaObserver){}
  ngOnInit(){
    this.mediaSub = this.mediaObserver.media$.subscribe((result:MediaChange)=>{
      console.log(result.mqAlias);
      this.deviceXs = result.mqAlias === 'xs' ? true : false;
      this.deviceSm = result.mqAlias === 'sm' ? true : false;
      this.deviceItsm = result.mqAlias === 'lt-sm' ? true : false;
      this.deviceItsm = result.mqAlias === 'lt-sm' ? true : false;
      this.deviceLg = result.mqAlias === 'lg' ? true : false;
    })
    this.onActivate(event);
  }
  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 3);
}
  ngOnDestroy(){
    this.mediaSub.unsubscribe();
  }

}
