import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-opulence',
  templateUrl: './opulence.component.html',
  styleUrls: ['./opulence.component.scss',
              './../app.component.scss'],
})

export class OpulenceComponent implements OnInit {
  // myInterval = 3000;
  myInterval = 300000;
  activeSlideIndex = 0;
  constructor() { }
   
  
  slides = [
    {image: 'assets/images/opulenceImage1.jpg'},
    {image: 'assets/images/opulenceImage2.jpg'},
    {image: 'assets/images/opulenceImage3.jpg'},
    {image: 'assets/images/opulenceImage4.jpg'}
  ];

  ngOnInit() {
  }

}
