<!-- 
@File : ai-ml.component.html
@Time : 2020/07/04 14:22:10
@Author : Supriya Suresh
@Version: 1.0
@Contact: supriya@melodale.com
@License: (c)Copyright 2020-2021, Melodale
@Desc : None 
-->

<div class="aiml000">
    <div class="aiml001a">
        <h1 class="aiml001aa mdfnt02">Artificial Intelligence  
            <br>& Machine Learning</h1>
    </div>
    <!-- <div class=""> -->

        <div class="aiml001b">
            <!-- <div class="io001bb"></div> -->
            <img class="aiml001bb" src="../../assets/images/AI.jpg"  alt="IoT">
        </div>

    <div class="aiml002">
        <h3 class="mdfnt02 aiml003">OCR Solutions & Servicing:</h3>
        <p class="aiml002a">Using google Tesseract OCR engine we provide extensive OCR (Optical Character Recognition) 
            of files in png, jpeg, tiff, bmp and gif formats. This engine gives us the flexibility to 
            process more than 100 languages and solve bilingual business use cases. This helps in resolving day to day business problem statements of converting data from file into 
            real usable data. This empowers businesses to invest this gained additional time to focus on more critical items. We can 
                also add customizations to this solution to integrate with multiple in house established products, tools or interfaces 
                which adds more value and boost your business deliverables.</p>
        <div class="aiml002ab">
            <!-- <div class="aiml002aba"></div> -->
            <img class="aiml002aba" src="../../assets/images/OCR.jpg">
        </div>
    </div>
    <div class="aiml004">
            <div class="aiml004a">
                <h3 class="mdfnt02 aiml003">
                    IoT with<br>
                    ARTIFICIAL INTELLIGENCE - MACHINE LEARNING
                </h3>
            </div>
            <div class="aiml004bb">
                <mat-card class="aiml0004 aiml00041">
                    <img mat-card-image class="aiml004aba"
                        src="../../assets/images/AIML1.jpg" alt="IoT">
                </mat-card>
    
                <mat-card class="aiml0004 aiml00042 aiml004b mdbkc002">
                    <mat-card-content>
                        <p class="aiml002b">
                            Fusing IOT with AI-ML empowers the product with a brain and body. This enables solutions to 
                            interact with connected devices and have a thinking/processing center to monitor, learn and take smart decisions.
                        </p>
                        <p class="aiml002b">This can be infused with wide range of products and improve standard of living for individuals, agriculture-IOT with 
                            Image recognition, Object detection etc. will make devices smart and intelligent and a wide spectrum of use cases can 
                            be explored, for instance in agriculture sector, this solution can be used to do early detection of nutrition deficiencies
                             or quality of crop etc. Apart from this IOT gives the power to make any device stay connected from your finger tips and 
                             addition of AI-ML will help these products to take smart decisions or send alerts when users are out of reach and important 
                             decision need to be taken to prevent any situation.
                            </p>
                    </mat-card-content>
                </mat-card>
            </div>
    </div>
</div>