<div class="sanitizerContainer">
    <div class="sanitizerSection1">
        <div class="sanitizerSubSection1">
            <span class="headerStyle1 alignTxtLeft mdfnt00">Smart Sanitizer</span><div class="buttonStyle2">
                <a mat-button class="buttonSubStyle1" routerLink="/contactus">
                    Buy Now</a>
            </div>
            <br/>
            
        <span class="textStyle1 spacer1">
            Ultraviolet light has been an old friend to the disinfection world for over a century. 
            UV-C carries the most energy capable of destroying the bonds that hold together the 
            DNA and RNA of viruses and bacteria, stopping them from working.
        </span>
        </div>
        <div class="sanitizerSubSection2">
            <!-- <div class="buttonStyle2">
                <a mat-button class="buttonSubStyle1" routerLink="/contactus">
                    Buy Now</a>
            </div> -->
            <!-- <div class="sanitizerSection3">  -->
                <div class="sanitizerSubSection4">
                    <img mat-card-image class="imageStyle4"
                        src="assets/images/sanitizer1.jpg"alt="Sanitizer">
                </div>
                <div class="sanitizerSubSection5">
                    <div class="sanitizerSubSection6">
                        <span>UV Santization</span>
                    </div>
                    <div class="sanitizerSubSection6">
                        <span>Safety system using Motion Sensor</span>
                    </div>
                    <div class="sanitizerSubSection6">
                        <span>Timer based Power Down</span>
                    </div>
                </div>
            <!-- </div> -->
        </div>
    <br/>
    </div>
    <div class="sanitizerSection2">
        <div class="sanitizerSubSection3">
            
        <span class="textStyle4">
            UV surface sterilization is highly effective as a valuable tool in many industries and 
            environments. Hospitals use UV sterilization to disinfect surgical equipment in patient 
            rooms. Surface sterilization is important in restaurants and commercial kitchens as well 
            as public places such as airports, bus stations and public transportation systems. UV lamps 
            significantly improve a sterile environment in hospitals and help to prevent the spread of disease.
        </span>
        
     
        
    </div>
    <div class="sanitizerSubSection7">
        <img  class="imageStyle3"
src="assets/images/sanitizer.jpg"alt="Sanitizer">
    </div>
</div >   

</div>