<div class="mn000">
	<mat-toolbar class="mn0000">
		<div fxShow="true" fxHide.gt-sm="true">
		  <button mat-icon-button (click)="sidenav.toggle()">
			<mat-icon>menu</mat-icon>
		  </button>
		</div>
	
		<a mat-button class="mn001a" routerLink="/home">
		  <!-- <span>Melodale</span> -->
		  <span><img src="../../assets/icons/meloLogo.png" alt="Melodale" class="logoCls"></span>

		</a>
		<span class="mn001"></span>
		<div fxShow="true" fxHide.lt-md="true">

			<!-- SUPRIYA CHANGES ON HOME WEBSITE 
			<div><span class="mn001b">
				     This website is under Development
			</span>
			</div> -->

			<button mat-button [matMenuTriggerFor]="product"
			#menuTrigger1="matMenuTrigger" (mouseover)="menuTrigger1.openMenu()">Our Product</button>
			<!-- <button mat-button [matMenuTriggerFor]="services"
			#menuTrigger2="matMenuTrigger" (mouseover)="menuTrigger2.openMenu()">Services & Solutions</button> -->
			<button mat-button routerLink="/aboutus">About Us</button>
			<button mat-button routerLink="/contactus">Contact Us</button>

			<mat-menu #product="matMenu" [overlapTrigger]="false" class="mni001">
				<span (mouseleave)="menuTrigger1.closeMenu()">
					<button mat-menu-item routerLink="/agrotics">Agrotics</button>
					<!-- <button mat-menu-item routerLink="/smartfence">Smart Fence</button>
					<button mat-menu-item routerLink="/elephantrepellent">Elephant Repellent System</button> -->
					<button mat-menu-item routerLink="/smartsantizer">Smart Sanitizer</button>
					<button mat-menu-item routerLink="/opulence">Opulence</button>
				</span>
			</mat-menu>

			<!-- <mat-menu #services="matMenu" [overlapTrigger]="false" class="mni001">
				<span (mouseleave)="menuTrigger2.closeMenu()">
					<button mat-menu-item routerLink="/iot">IoT</button>
					<button mat-menu-item routerLink="/aiml">AI & ML</button>
				</span>
			</mat-menu> -->


			

		</div>
	  </mat-toolbar>

	  <mat-sidenav-container fxFlexFill class="example-container">
		<mat-sidenav class="mn003" #sidenav fxLayout="column" mode="over" opened="false" fxHide.gt-sm="true">
		  <div fxLayout="column">
			<a mat-list-item class="mn002" (click)="toggleMenu1()">Our Product
				<mat-icon class="mn002a" *ngIf="!showMenu1">chevron_right</mat-icon>
				<mat-icon class="mn002a" *ngIf="showMenu1">expand_more</mat-icon>
			</a>
			<mat-nav-list *ngIf="showMenu1">
				<a mat-list-item (click)="sidenav.toggle()" routerLink="/opulence">Opulence</a>
			</mat-nav-list>
			<a mat-list-item class="mn002" (click)="toggleMenu2()">Services & Solutions
				<mat-icon class="mn002a" *ngIf="!showMenu2">chevron_right</mat-icon>
				<mat-icon class="mn002a" *ngIf="showMenu2">expand_more</mat-icon>
			</a>
			<mat-nav-list *ngIf="showMenu2">
				<a mat-list-item (click)="sidenav.toggle()" routerLink="/iot">IoT</a>
				<a mat-list-item (click)="sidenav.toggle()" routerLink="/aiml">AI & ML</a>
			</mat-nav-list>
			<a mat-list-item class="mn002" (click)="sidenav.toggle()" routerLink="/aboutus">About Us</a>
			<a mat-list-item class="mn002" (click)="sidenav.toggle()" routerLink="/contactus">Contact Us</a>
		  </div>
		</mat-sidenav>
		<mat-sidenav-content>
			<!-- TO SCROLL TO TOP -->
			<!-- <router-outlet (activate)="onActivate($event)"></router-outlet> -->
			<router-outlet (activate)="onActivate($event)"></router-outlet>
		</mat-sidenav-content>
	  </mat-sidenav-container>

</div>
