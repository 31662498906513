<div>
  <h1>USER REGISTRATION</h1>

  <div class="col-12">
      <div class="row">
          <div class="col-6 ae11">
              
          </div>
          <div class="col-6">
              <img class="ae01" [src]="imageUrl"> <br/>
              <!-- *******************NEED TO CHANGE******************* -->
              <!-- <input type='file' [(ngModel)]="image" #Image accept="image/*" (change)="onSelectFile($event.target.files)"> -->
              <input type='file' >
            </div>
          <div>
      </div>
  </div>
</div>
<div class="col-12 ae00">
  <div class="row">
      <div class="col-5">
          <label class="ae12 aeFont" for="fnText">First Name</label>
      </div>
      <div class="col-5">
          <label class="ae12 aeFont" for="fnText">Last Name</label>
      </div>
  </div>
  <div class="row">
      <div class="col-5">
          <input class="ae02" [(ngModel)]="firstname" type="text" id="InText1">
      </div>
      <div class="col-5">
          <input class="ae02" [(ngModel)]="lastname" type="text" id="lnText">
      </div>
  </div>
  <div class="row">
      <div class="col-5 ae03">
          <label class="ae12 aeFont" for="fnText">Designation</label>
      </div>
      <div class="col-5 ae03">
          <label class="ae12 aeFont" for="fnText">Employee Id</label>
      </div>
  </div>
  <div class="row">
      <div class="col-5">
          <input class="ae02" [(ngModel)]="Designation" type="text" id="InText1">
      </div>
      <div class="col-5">
          <input class="ae02" [(ngModel)]="Employeeid" type="text" id="lnText">
      </div>
  </div>    
  <div class="row">
      <div class="col-6 ae03">
          <label class="ae12 aeFont" for="fnText">Email Address</label>
      </div>
      <div class="col-6 ae03">
        <label class="ae12 aeFont" for="fnText">password</label>
      </div>
  </div>
  <div class="row">
      <div class="col-6">
          <input class="ae02" [(ngModel)]="Email" type="text" id="InText1">
      </div>
      <div class="col-6">
        <input class="ae02" [(ngModel)]="password" type="password" id="InText2">
      </div>
  </div>  
  
     
  <div class="row ae04">
      <div class="col-12">
          
          <button mat-flat-button class="ae05 ae07 aeFont"(click)="onSave()">Save</button>
          
      </div>           
  </div>
   
</div>