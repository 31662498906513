<div class="mdht00">
    <div class="agroSection1 mdbkc002">
        <div class="agroSubSection1">
            <span class="headerStyle2 alignTxtLeft mdfnt00">Agrotics</span>
            <br/>
            <span class="textStyle1">Agriculture is  a complex activity where every segment needs a defined and regular attention.
                Customers can monitors all the details about humidity & temperature in a clear precise display 
                on the device from field monitoring.</span>
            <div class="buttonStyle1">
                <a mat-button class="mdc002 buttonSubStyle1 mdbkImg01" routerLink="/contactus">
                    Let's start your journey with us !</a>
            </div>
    </div>
    <div class="agroSubSection2">
        <img mat-card-image class="imageStyle1"
            src="assets/images/Agro4.jpg"alt="Agrotics">
    </div> 
  </div>
  <div class="agroSection2">
      <div class="agroSubSection3">
        <span class="textStyle2">
            Our product reduces human efforts by automating irrigation, considering various factors such as crop growth 
            stage, soil, crop, weather conditions. We accomplish this by checking for temperature, humidity, growth light, 
            moisture. When water facility is limited, crop maintenance is vital. We assist this essential activity with flow
             sensors where customers can control the water flow.
        </span>
      </div>
  </div>
  <div class="agroSection3 mdbkc002">
    <div class="agroSubSection1">
        <div class="agroSubSection4">
            <span class="textStyle3">Spontaneous monitoring  and controlling can be done via a handy mobile app which includes 
                features like monitoring temperature, humidity, soil moisture, weather data, water quantity, reminders, manual 
                and automatic control, automatic growth light control and record of last watered date.
            </span>
        </div>
        <div class="agroSubSection5">
            <span class="textStyle3">Our product reduces human efforts by automating  irrigation, considering various factors 
                such as crop growth stage, soil, crop, weather conditions by checking for temperature, humidity, 
                growth light, moisture.
        </span>
        </div>
        <div class="agroSubSection6">
            <span class="textStyle3">
                Customers can monitor all the details about humidity, temperature etc. in a clear precise display on the device from 
                field monitoring.
        </span>
        </div>
        <div class="agroSubSection7">
            <span class="textStyle3">
                With all these features, 
                our product replaces entire set of tedious work to a few on-click-tasks !
        </span>
        </div>
</div>
<div class="agroSubSection2">
    <img mat-card-image class="imageStyle2"
        src="assets/images/Agro5.jpg"alt="Agrotics">
</div> 
</div>
</div>
  
  
  