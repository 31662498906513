<!-- 
    @File       :   price.component.html
    @Time       :   2020/05/12  09:22:10
    @Author     :   Supriya Suresh
    @Version    :   1.0
    @Contact    :   supriya@melodale.com
    @License    :   (c)Copyright 2020-2021, Melodale
    @Desc       :   None 
-->

<div class="pc000 mdbkc002">
    <h2 class="pc00 mdc000 mdfnt00">Pricing offers just for You !</h2>

    <span class="pc00a mdc003 mdfnt01">Select from our ranges of packages</span>

  
    <mat-button-toggle-group class="pc01" value="monthly" matRipple >
        <mat-button-toggle class="pc02" (click)="onPrice(0)" value="monthly">Monthly</mat-button-toggle>
        <mat-button-toggle class="pc02" (click)="onPrice(1)">Half Yearly</mat-button-toggle>
        <mat-button-toggle class="pc02" (click)="onPrice(2)">Yearly</mat-button-toggle>
    </mat-button-toggle-group> 

    <div class="pc03">
        <div class="pc04" *ngFor="let pricingName of getPricingNames(); let i = index">
            <div class="pc05">
                <div class="pc06">
                    <span class="pc0600">
                        {{pricingName}}</span>
                </div>
                <hr class="pc06001" [style.borderColor]="getBackgroundColor(i)">
                <div class="pc07">
                    <div><sup class="pc08">Rs.</sup> 
                        <p class="pc09">
                        {{getStudentPrice(int)}}</p> <sub>/ {{getFreq(int)}}</sub></div>
                </div>
                <div class="pc10" [style.backgroundColor]="getBackgroundColor(i)">
                    <button mat-button (click)="btnHandler(getPackage(i), getFreq(int))" 
                    routerLink="/owner_registration_saver">
                    <b>Contact Us</b></button>
                </div>
            </div>
            <div class="pc11">
                <div class="pc12" *ngFor="let labelNm of getFeatureList(i)">
                    {{labelNm}}
                    <hr class="pc13">
                </div>
            </div>
        </div>
    </div>
</div>