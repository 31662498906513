import { Component, OnInit, Inject} from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar,MatSnackBarConfig} from '@angular/material/snack-bar';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss',
              './../app.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [style({ opacity: 0 }), animate('700ms', style({ opacity: 1 }))]),
      transition('* => void', [style({ opacity: 1 }), animate('700ms', style({ opacity: 0 }))]),
    ])
  ]
})
export class HomeComponent {

  /* constructor(public snackBar: MatSnackBar,public config:MatSnackBarConfig) {
    this.config.duration = this.setAutoHide ? this.autoHide : 0;

    this.snackBar.open("message", "act", {
      duration: 100000,
    });
    

    
  } */
  ngOnInit() {

   /*  this.snackBar.open("message", "act", {
      duration: 100000,
    }); */
    
  }
}
