import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS,  } from '@angular/forms';

@Directive({
  selector: '[appFnameValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: FnameValidatorDirective, multi: true}]
})
export class FnameValidatorDirective implements Validator {

  constructor() { }
  validate(control: AbstractControl) {
    const elementValue = control.value;

    if (elementValue === null || elementValue === undefined || elementValue === '') {
      return {'cus_required' : 'Field is required'};
    }

    
    
    if (elementValue.MinLengthValidator) {
      return {'cus_pattern' : 'Minimum 3 Characters required.'};
    }

    return null;
  }

}
