
// @File : ai-ml.component.ts
// @Time : 2020/07/04 14:22:10
// @Author : Supriya Suresh
// @Version: 1.0
// @Contact: supriya@melodale.com
// @License: (c)Copyright 2020-2021, Melodale
// @Desc : None 
 

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-ml',
  templateUrl: './ai-ml.component.html',
  styleUrls: ['./ai-ml.component.scss',
              './../app.component.scss'],
})
export class AiMLComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
