import { FormControl, ValidationErrors } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { User } from './model/contactus.model'; 
import {ICountry} from './model/country.model';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

//import {GetcountrycodeService} from '../service/getcountrycode.service';
import { Subscription } from 'rxjs';
import * as data from '../../assets/json/countries.json';
import {ContactusService, Contactdetails} from '../../app/services/contactus.service'


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss',
              './../app.component.scss'],
})
export class ContactUSComponent implements OnInit {


  selectedCountry:any  = 'IN';
  selectedPhoneNumber :any;
  //countries : any [];
  subscription: Subscription;
  reactiveForm: FormGroup;
  owner = new User();
  public contactdetails: Contactdetails;
  msg: string = null;
  showMsg: boolean = false;
  submitted = false;

  constructor(private _fb: FormBuilder,
              private contactusservice: ContactusService) { }

  ngOnInit() {
    this.reactiveForm = this._fb.group({
      first_name:[null, [Validators.required, Validators.minLength(2)]],
      last_name: [null, Validators.required],
      // email : [null,[Validators.required,Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
      email : [null,[Validators.required,Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)]],
      // email : [null,[Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      
      phone_num : [null, [Validators.required,this.validatePhoneNum]],
      country_code: [null, Validators.required],
      acceptTerms: [false, Validators.requiredTrue],
      profile: this._fb.group({
      organization_name: [null, Validators.required],
      // user_message : [null, Validators.required],
      }),


    });

  }
  
  get organization_name() {
    const temp = <FormGroup>this.reactiveForm.controls.profile;
    return temp.controls.organization_name;
  }

  // get user_message() {
  //   const temp = <FormGroup>this.reactiveForm.controls.profile;
  //   return temp.controls.user_message;
  // }

  get first_name() {
    return this.reactiveForm.get('first_name');
  }
 get email() {
    return this.reactiveForm.get('email');
  }
  get last_name() {
    return this.reactiveForm.get('last_name');
  }
  get phone_num() {
    return this.reactiveForm.get('phone_num');
  }
  get country_code() {
    return this.reactiveForm.get('country_code');
  }

 

  onSubmit() {
      //  this.contactusservice.addcontact(this.reactiveForm.value).then();
      
    if (this.reactiveForm.valid) {
      this.submitted = true;
      this.contactusservice.addcontact(this.reactiveForm.value)
      .then( data => {
       this.showMsg= true;
     });
    }
    else if (this.reactiveForm.invalid) {
          return;
      }


  }


  countries :any = (data as any).default; 

  
  resetPhoneNumber(event: any): void {
		this.reactiveForm.controls['phone_num'].setValue('');
  }

  validatePhoneNum(control: AbstractControl):{[key: string]: boolean} {
    const elementValue = control.value;
    var phonenumber = "";
    if (elementValue !== null)  {
      phonenumber = elementValue.substr(elementValue.indexOf(' ')+1);
      if ((phonenumber.replace(/ /g,"").length != 10) || (/^[a-zA-Z]+$/.test(phonenumber.replace(/ /g,""))))
        {
          return {"ValidPhoneNumber":true};
        }
    }
  }
  
  formatPhoneNumber(event: any): void {
    let inputValue: any = this.reactiveForm.controls['phone_num'].value;
    let countryselect :any = this.reactiveForm.controls ['country_code'].value;

    let phoneNumber: any = parsePhoneNumberFromString(inputValue, countryselect);
    
		if(phoneNumber){
      this.selectedPhoneNumber = phoneNumber.number;
			this.reactiveForm.controls['phone_num'].setValue(phoneNumber.formatInternational());
		}
  }

}
