<!-- 
    @File       :   opulence.component.html
    @Time       :   2020/05/12  09:22:10
    @Author     :   Supriya Suresh
    @Version    :   1.0
    @Contact    :   supriya@melodale.com
    @License    :   (c)Copyright 2020-2021, Melodale
    @Desc       :   None 
-->

<div class="mdht00">
  <div class="oc000 mdht00 mdbkc002">
    <div class="oc000a">
      <!-- V1.0 Release changes -->
      <!-- <img class="oc000aa" src="/assets/images/OpulenceIcon.jpg" alt="Opulence"> -->
      <span class="oc0001 mdfnt00">Let's start the journey together with the plan suits you !</span>
    </div>
    <div class="oc000b">
      <!-- V1.0 Release changes -->
      <!-- <span class="oc0001 mdfnt00">Let's start the journey together with the plan suits you ! -->
        <a mat-button class="oc0002 mdc002 mdbkc000 mdfnt00" routerLink="/contactus">
          <!-- V1.0 Release changes -->
          <!-- Join Today -->
        Launching Soon </a>
        <!-- V1.0 Release changes -->
      <!-- </span> -->
    </div>
  </div>
  <h2 class="oc20a  mdfnt00">Opulence Dashboard</h2>
  <div class="oc001">
    <mat-card class="oc20 mdbkc002">
        <mat-card-content>
          <span class="oc01ab mdfnt01">Provide quick summary of current project status in a glance
            which includes important milestones, project tracker, budget analysis and more</span>
        </mat-card-content>
    </mat-card>

    <mat-card class="oc20 mdbkc002">
      <!-- SUPRIYA CHANGES ON HOME WEBSITE
        <img mat-card-image class="hmimg22a000"
            src="../../assets/images/op1.jpg"alt="Dashboard"> -->
        <img mat-card-image class="hmimg22a000"
            src="../../assets/images/Daledashboard.JPG"alt="Dashboard">
            
        <mat-card-content>
        </mat-card-content>
    </mat-card>
</div>

<h2 class="oc20a mdfnt00">Timesheet Tracker</h2>
<div class="oc001">
  <mat-card class="oc20 mdbkc002">
    <img mat-card-image class="hmimg22a000"
        src="../../assets/images/timesheet.jpg"alt="Dashboard">
    <mat-card-content>
    </mat-card-content>
</mat-card>

  <mat-card class="oc20 mdbkc002">
      <mat-card-content>
        <span class="oc01ab mdfnt01">Helps us to provide the fastest update against the Time. 
          Also enhanced in such a way to assign/reassign the tasks quickly.</span>
      </mat-card-content>
  </mat-card>
</div>

<h2 class="oc20a mdfnt00">Task Chart</h2>
<div class="oc001">
  <mat-card class="oc20 mdbkc002">
      <mat-card-content>
        <span class="oc01ab mdfnt01">Task chart can provide Kanban view of all task 
          against the team.</span>
      </mat-card-content>
  </mat-card>

  <mat-card class="oc20 mdbkc002">
    <!-- SUPRIYA CHANGES ON HOME WEBSITE
      <img mat-card-image class="hmimg22a000"
          src="../../assets/images/op3.jpg"alt="Dashboard"> -->
      <img mat-card-image class="hmimg22a000"
          src="../../assets/images/Kanbanboard.JPG"alt="Dashboard">
      <mat-card-content>
      </mat-card-content>
  </mat-card>
</div>

<!-- SUPRIYA CHANGES -->
<!-- 
<h2 class="oc20a mdfnt00">Budget Tracker</h2>
<div class="oc001">
  <mat-card class="oc20 mdbkc002">
      <img mat-card-image class="hmimg22a000"
          src="../../assets/images/op4.jpg"alt="Dashboard">
      <mat-card-content>
      </mat-card-content>
  </mat-card>

  <mat-card class="oc20 mdbkc002">
      <mat-card-content>
        <span class="oc01ab mdfnt01">For tracking Budget and project estimation.</span>
      </mat-card-content>
  </mat-card>
</div> -->

    
  <!-- <div class="oc03">
    <div class="oc002b">
      <span class="oc00022 mdfnt00">Please see our varying packages that suits
        each project and company requirements.
      </span>
      <a mat-button class="oc0003 mdc002 mdbkc000 mdfnt00" routerLink="/price">
        Pricing Details</a>
    </div>
  </div> -->
<!-- </div> -->