import { Directive } from '@angular/core';

@Directive({
  selector: '[appStartEndDatevalidator]'
})
export class StartEndDatevalidatorDirective {

  constructor() { }

}
