<div class="cu000">
    <div class="cu001">
        <mat-card class="cu002">
            <mat-card-header>
                <mat-card-title class="cub3">
                    Contact our sales team
                </mat-card-title> 
                <mat-card-subtitle class="cub4">
                    Please share your contact details and our sales team will be in touch with you right away.
                </mat-card-subtitle>
            </mat-card-header>

            <div class="cu19">
                <mat-card class="cu20 mdbkc002">
                    <mat-card-content>
                        <div class="cu20a">
                            <div>
                                <div class="cub002aa">
                                    <mat-icon class="cub002aaa cub2">call</mat-icon>
                                </div>  
                            </div>
                            <div>
                                <div class="cu005 mdfnt01 mdc001">
                                    <span class="cub002ab">Call Us</span>
                                </div>
                                <div class="cub002caa">
                                    <div class="cub002ca">
                                        <a href="tel:+18602122423" class="cub002cca">
                                            +1 (860)-212-2423</a>
                                    </div>
                                    <div class="cub002ca">
                                        <!-- SUPRIYA CHANGES ON HOME WEBSITE
                                        <a href="tel:+919846376622" class="cub002cca">
                                            +91-98463-76622</a> -->
                                        <a href=" " class="cub002cca">
                                            +91 97467-09241</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <hr class="cu20aa">
                <mat-card class="cu20 mdbkc002">
                    <mat-card-content>
                        <div class="cu20a">
                            <div>
                                <div class="cub002aa">
                                    <mat-icon class="cub002aaa cub2">email</mat-icon>
                                </div>  
                            </div>
                            <div>
                                <div class="cu005 mdfnt01 mdc001">
                                    <span class="cub002ab">Email Us</span>
                                </div>
                                <div class="cub002caa">
                                    <div class="cub002c">
                                        <a href="mailto:info@melodale.com" class="cub002cc">
                                            info@melodale.com</a>
                                    </div>
                                    <div class="cub002ca">
                                        <a></a> 
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>    
        </mat-card>

        <mat-card class="cu003">
            <div class="container-fluid">
                <div>
                    <div>
                        <form action="" class="prodRegtrForm" [formGroup]="reactiveForm" (ngSubmit)="onSubmit()">
                            <div class="cu003abc">
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="fnText" class="cu003aa mdfnt01">
                                                <span>First Name</span>
                                                <span class="cu003aa1">*</span>
                                            </label>
                                            <input type="text" [ngClass]="{'form-control': true,
                                                'is-invalid': submitted && !first_name.valid && first_name.touched,
                                                'is-valid': first_name.valid }" formControlName="first_name"
                                                id="InText1">
                                            <small *ngIf="first_name.errors?.required && first_name.touched" id="fnHelp"
                                                class="form-text custom-invalid-feedback">Field
                                                is required</small>
                                            <small *ngIf="first_name.errors?.minlength && first_name.touched"
                                                id="fnHelp" class="form-text custom-invalid-feedback">Name
                                                should be atleast 2 characters.</small>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="fnText" class="cu003aa mdfnt01">
                                                <span>Last Name</span>
                                                <span class="cu003aa1">*</span>
                                            </label>
                                            <input type="text" [ngClass]="{'form-control': true,
                                                'is-invalid': !last_name.valid && last_name.touched,
                                                'is-valid': last_name.valid}" formControlName="last_name" id="lnText">
                                            <small id="fnHelp" *ngIf="last_name.errors?.required && last_name.touched"
                                                class="form-text custom-invalid-feedback">Field
                                                is required</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="fnText" class="cu003aa mdfnt01">
                                                <!-- <span>Company Email</span> -->
                                                <span>Email</span>
                                                <span class="cu003aa1">*</span>
                                            </label>
                                            <input type="text" [ngClass]="{'form-control': true,
                                                'is-invalid': !email.valid && email.touched,
                                                'is-valid': email.valid}" appEmailValidator
                                                autocomplete="current-email" formControlName="email" id="fnText">
                                            <small *ngIf="email.errors?.required && email.touched" id="fnHelp"
                                                class="form-text custom-invalid-feedback">Field
                                                is required</small>
                                            <small *ngIf="email.errors?.pattern && email.touched" id="fnHelp"
                                                class="form-text custom-invalid-feedback">This
                                                is not a valid email id.</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="fnText" class="cu003aa mdfnt01">
                                                <span>Phone number</span>
                                                <span class="cu003aa1">*</span>
                                            </label>
                                            <!--*******************ALREADY COMMENTED LINES STARTS*******************-->
                                            <!-- <input type="text" [ngClass]="{'form-control': true,
                                                'is-invalid': !country_code.valid && country_code.touched,
                                                'is-valid': country_code.valid}" formControlName="country_code" id="InText1"> -->
                                            <!--  <small
                                                    *ngIf="country_code.errors?.required && country_code.touched"
                                                    class="form-text custom-invalid-feedback">Field
                                                    is required</small>  -->
                                            <!--*******************ALREADY COMMENTED LINES ENDS*******************-->
                                            <small *ngIf="countries">
                                                <select (change)="resetPhoneNumber($event)"
                                                    [ngClass]="{'form-control': true}" formControlName="country_code"
                                                    id="InText1">
                                                    <option *ngFor="let country of countries" [ngValue]="country.code">
                                                        {{country.name}}</option>
                                                </select>
                                            </small>



                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="fnText" class="cu003aa mdfnt01">
                                                <span></span>
                                                <span class="cu003aa1"></span>
                                            </label>
                                            <input type="text" (input)="formatPhoneNumber($event)" phoneMask [ngClass]="{'form-control': true,
                                                'is-invalid': !phone_num.valid && phone_num.touched,
                                                'is-valid': phone_num.valid}" maxlength="20"
                                                formControlName="phone_num" id="lnText">
                                            <small *ngIf="phone_num.errors?.required && phone_num.touched" id="fnHelp"
                                                class="form-text custom-invalid-feedback">Field
                                                is required</small>
                                            <!--*******************ALREADY COMMENTED LINES STARTS*******************-->
                                            <!-- <small
                                                *ngIf="phone_num.errors.ValidPhoneNumber && phone_num.touched"
                                                class="form-text custom-invalid-feedback">Please enter valid 
                                                phone number</small>-->
                                            <!--*******************ALREADY COMMENTED LINES ENDS*******************-->
                                        </div>
                                    </div>
                                </div>
                                <div class="row" formGroupName="profile">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="fnText" class="cu003aa mdfnt01">
                                                <span>Company Name</span>
                                                <span class="cu003aa2">(optional)</span>
                                                <!-- <span class="cu003aa1">*</span> -->
                                            </label>
                                            <input type="text" id="orgName" 
                                            [ngClass]="{'form-control': true}"
                                                formControlName="organization_name">
                                            <!-- <small
                                                *ngIf="organization_name.errors?.required && organization_name.touched"
                                                class="form-text custom-invalid-feedback">Field
                                                is required</small> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="row" formGroupName="profile">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="fnText" class="cu003aa mdfnt01">
                                                <span>Type your message</span>
                                            </label>
                                            <!-- <textarea type="text" id="usrMsg"
                                            [ngClass]="{'form-control': true}"></textarea> -->
                                            <textarea type="text" id="usrMsg"
                                            [ngClass]="{'form-control': true}"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="cu003aab">
                                    <div>
                                        <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" 
                                        class="form-check-input" [ngClass]="{ 'is-invalid': submitted }" />
                                        <label for="acceptTerms" class="cu003aab1">
                                            I agree to receive additional communications from Melodale
                                        </label>


                                        <!-- <mat-checkbox formControlName="acceptTerms" 
                                        [ngClass]="{'is-invalid': submitted}">
                                            <div class="cu003aab1a">
                                                <span class="cu003aab1">
                                                    I agree to receive additional communications from Melodale.
                                                </span>
                                            </div>
                                        </mat-checkbox> -->
                                    </div>
                                    <div class="cu003aab1">
                                        <p><a class="cu003aab2" routerLink="/privacy">View our Privacy policies here</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="row cu004aa">
                                    <div class="col-2">
                                        <button type="submit"
                                            class="btn cu004aa1 mdfnt00">
                                            Send Message     
                                        </button>

                                        <div class="row cu004aa2" *ngIf="showMsg">
                                            <div class="col-xs-12">
                                              <p class="alert alert-success">
                                                  <strong>Thank you for getting in touch !</strong>
                                                  We appreciate you contacting Melodale. One of our colleagues will get back in touch with you soon ! 
                                                  Have a great day !
                                                </p>    
                                            </div>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>