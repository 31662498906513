import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { pathToFileURL } from 'url';

@Injectable({
  providedIn: 'root'
})
export class ContactusService {

  constructor(
    private firestore: AngularFirestore
  ) { }

    addcontact(payload: Contactdetails){
      return this.firestore.collection('contact').add(payload);
    }

}
export interface Contactdetails {
id?: string;
FirstName : string;
LastName : string;
CompanyName : string;
Phone : string;
Message : string;
}