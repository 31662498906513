 
    // @File       :   privacy.component.html
    // @Time       :   2020/06/08  09:22:10
    // @Author     :   Supriya Suresh
    // @Version    :   1.0
    // @Contact    :   supriya@melodale.com
    // @License    :   (c)Copyright 2020-2021, Melodale
    // @Desc       :   None 


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss',
              './../app.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
