<!-- 
@File : iot.component.html
@Time : 2020/09/21 14:22:10
@Author : Supriya Suresh
@Version: 1.0
@Contact: supriya@melodale.com
@License: (c)Copyright 2020-2021, Melodale
@Desc : None 
-->

<div class="io000">
    <div class="io001">
        <h1 class="io001aa mdfnt02">INTERNET OF THINGS</h1>
    </div>
    <!-- <div class=""> -->

        <div class="io001a">
            <!-- <div class="io001bb"></div> -->
            <img class="io001bb" src="../../assets/images/IoT.jpg"  alt="IoT">
        </div>
        <!-- <div class="io001a">

        </div> -->
    <!-- </div>ss -->


    <div class="io002">
        <p class="io002a">
            The Internet of Things, or IoT, is a system of interrelated computing devices, mechanical and
            digital machines, objects, animals or people that are provided with unique identifiers (UIDs) and
            the ability to transfer data over a network without requiring human-to-human or
            human-to-computer interaction. <br>
            It connects people to people , people to things and things to things.
        </p>
        <p class="io002b">Our end-to-end IoT solutions enables secure connection between all web enabled machines ,
            facilitates processed/raw data across and activates/deactivates them based on chosen criteria.
            It enables products to operate smarter as well as gain complete control of the each data/action
            done ,which is a part of our perfect product management package.
        </p>
        <p class="io002b">
            Our engineers are well equipped and expertise across overall IOT adoptions to provide better
            experience for our customers.
        </p>
       
       
    </div>
    
</div>