<!-- 
    @File       :   footer.component.html
    @Time       :   2020/05/12  09:22:10
    @Author     :   Supriya Suresh
    @Version    :   1.0
    @Contact    :   supriya@melodale.com
    @License    :   (c)Copyright 2020-2021, Melodale
    @Desc       :   None 
-->


<div class="hm12">
  <div class="hm40">
    <div class="hm41">
      <div class="hm42">
        <div>
          <a mat-button class="hm42a" routerLink="/home">
            <!-- <span class="mdfnt02 mdc003">Melodale</span><span class="hm05"></span> -->
            <span><img class="logoCls" src="../../assets/icons/footerMelologo.png" alt="Melodale"></span><span class="hm05"></span>
          </a>
        </div>
        <div>
          <mat-icon class="hm4000">mail_outline</mat-icon>
          <a href="mailto:info@melodale.com" class="hm0041bb mdc003 mdfnt00">
            info@melodale.com</a>
        </div>
        <div class="hm42aa">
          <span class="mdfnt01 mdc003">Follow Us</span>
        </div>
        <div class="hm42b row">
          <a class="hm42bb hm420a" href="https://www.linkedin.com/"></a>
            <!-- <img class="hm42bb" src="/assets/icons/linkedin.svg" alt="LinkedIn"></a> -->
            <!-- SUPRIYA CHANGES ON HOME WEBSITE
          <a class="hm42bb hm420b" href="https://twitter.com/explore"></a> -->
            <!-- <img class="hm42bb" src="/assets/icons/twitter.svg" alt="Twitter"></a> -->
          <a class="hm42bb hm420c" href="https://www.instagram.com/?hl=en"></a>
            <!-- <img class="hm42bb" src="/assets/icons/instagram.svg" alt="Instagram"></a> -->
            <!-- <a class="hm42bb hm420d" href="https://www.youtube.com/"></a> -->
            <!-- <img class="hm42bb" src="/assets/icons/youtube.svg" alt="YouTube"></a> -->
          <a class="hm42bb hm420e" href="https://www.facebook.com/"></a>
        </div>
      </div>
    </div>

    
    <div class="hm41b row">
      <div class="hm0041a mdc001 mdfnt00">Product
        <div>
          <a mat-button class="hm00041 hm0041aa mdfnt01" routerLink="/agrotics">Agrotics</a>
          <a mat-button class="hm00041 hm0041aa mdfnt01" routerLink="/smartsantizer">Smart Sanitizer</a>
          <a mat-button class="hm00041 hm0041aa mdfnt01" routerLink="/opulence">Opulence</a>
        </div>
      </div>
     

      
      <div class="hm0041a mdc001 mdfnt00">Company
        <div>
          <a mat-button class="hm00041 hm0041aa mdfnt01" routerLink="/aboutus">About Us</a>
          <a mat-button class="hm00041 hm0041aa mdfnt01" routerLink="/contactus">Contact Us</a>
          <a mat-button class="hm00041 hm0041aa mdfnt01" routerLink="/privacy">Privacy Policy</a>
        </div>
      </div>
    
      

    </div>
    
<!-- 
    <div class="hm41b">
      <div class="hm0041a mdc001 mdfnt00">Product</div>
      <div class="hm0041b">
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/agrotics">Agrotics</a>
        </div>
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/price">Pricing</a>
        </div>
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/">Features</a>
        </div>
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/">Tour</a>
        </div>
      </div>
      <div class="hm0041b">
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/smartfence">Smart Fence</a>
        </div>
      </div>
      <div class="hm0041b">
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/elephantrepellent">Elephant Repellent System</a>
        </div>
      </div>
      <div class="hm0041b">
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/smartsantizer">Smart Sanitizer</a>
        </div>
      </div>
      <div class="hm0041b">
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/opulence">Opulence</a>
        </div>
      </div>

    </div>
    <div class="hm41b">
      <div class="hm0041a mdc001 mdfnt00">Services & Solutions</div>
      <div class="hm0041b">
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/iot">IoT</a>
        </div>
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/aiml">AI & ML</a>
        </div>

 <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/medical">Medical Services</a>
        </div>
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/newproduct">New Product Development</a>
        </div> 
     </div>
    </div>
    <div class="hm41b">
      <div class="hm0041a mdc001 mdfnt00">Company</div>
      <div class="hm0041b">
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/company">Our Company</a>
        </div>
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/aboutus">About Us</a>
        </div>
      <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/team">Our Team</a>
        </div> 
        <div class="hm00041 mdc001 mdfnt01"> 
          <a mat-button class="hm0041aa mdfnt01" routerLink="/contactus">Contact Us</a>
        </div>
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/news">News & Updates</a>
        </div>
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/privacy">Privacy Policy</a>
        </div>
        <div class="hm00041 mdc001 mdfnt01">
          <a mat-button class="hm0041aa mdfnt01" routerLink="/termconditions">Terms and Conditions</a>
        </div>
      </div>
    </div> -->
    
    <div class="hm42abb">
      <span>© {{currYr}} Melodale Technology Solutions Private Limited. 
      All Rights Reserved.</span>
    </div>
  </div>
</div>