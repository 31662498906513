<div class="container-fluid prodRegtrContainer">  
    <div class="prodRegDetail">
        <div class="prodRegDetailContainer">
              <!-- <div class="col"> -->
  <form action="" class="prodRegtrForm" [formGroup]="reactiveForm">     
      <div>    
          <h2 class="regHdr">Sign Up here !</h2>
      </div>
    <div class="formFillinCls">
      <!-- personal info -->
      <div class="row">
          <div class="col">
          <label for="fnText" class="regLbl">Full Name</label>
      </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <!-- <label for="fnText">First Name</label> -->
            
            <input type="text" [ngClass]="{'form-control': true,
                'is-invalid': !first_name.valid && first_name.touched,
                'is-valid': first_name.valid}"
              formControlName="first_name" id="InText1" placeholder="First Name" 
              class="fldFmt1 wd-95">
            <small *ngIf="first_name.errors?.required && first_name.touched" id="fnHelp" class="form-text custom-invalid-feedback">Field
              is required</small>
            <small *ngIf="first_name.errors?.minlength && first_name.touched" id="fnHelp" class="form-text custom-invalid-feedback">Name
              should be atleast 2 characters.</small>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <!-- <label for="lnText">Last Name</label> -->
            
            <input type="text" [ngClass]="{'form-control': true,
                'is-invalid': !last_name.valid && last_name.touched,
                'is-valid': last_name.valid}"
              formControlName="last_name" id="lnText" placeholder="Last Name" 
              class="fldFmt1 wd-95">
            <small id="fnHelp" *ngIf="last_name.errors?.required && last_name.touched" class="form-text custom-invalid-feedback">Field
              is required</small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="fnText" class="regLbl">Email</label>
            <input type="text" [ngClass]="{'form-control': true,
                'is-invalid': !email.valid && email.touched,
                'is-valid': email.valid}"
              appEmailValidator autocomplete="current-email" formControlName="email" id="fnText" placeholder="Email"
              class="fldFmt1">
            <small *ngIf="email.errors?.required && email.touched" id="fnHelp" class="form-text custom-invalid-feedback">Field
              is required</small>
            <small *ngIf="email.errors?.pattern && email.touched" id="fnHelp" class="form-text custom-invalid-feedback">This
              is not a valid email id.</small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="fnText" class="regLbl">Password</label>
            <input type="password" [ngClass]="{'form-control': true,
              'is-invalid': !password.valid && password.touched,
              'is-valid': password.valid}"
              appPasswordValidator autocomplete="current-password" placeholder="Password" 
              class="fldFmt1 wd-95" formControlName="password" />
            <small *ngIf="password.errors?.required && password.touched" id="fnHelp" class="form-text custom-invalid-feedback">Field
              is required</small>
            <small *ngIf="password.errors?.pattern && password.touched" id="fnHelp" class="form-text custom-invalid-feedback">This
              is not a valid password, use a capital letter, number and symbol.</small>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="regLbl">Confirm Password</label>
            <input type="Password" [ngClass]="{'form-control': true,
                      'is-invalid': !confirmPassword.valid && confirmPassword.touched,
                      'is-valid':confirmPassword.valid }"
              compare="password" autocomplete="current-password" formControlName="confirmPassword"
              class="form-control fldFmt1 wd-95" placeholder="Confirm Password" />
            <small *ngIf="confirmPassword.errors?.required && confirmPassword.touched" id="fnHelp" class="form-text custom-invalid-feedback">Field
              is required</small>
            <small *ngIf="confirmPassword.errors?.compare" id="fnHelp" class="form-text custom-invalid-feedback">Passwords
              do not match.</small>
          </div>
        </div>
      </div> 
      


      <!-- seperator -->
      <div class="row">
        <div class="col">
          <hr>
        </div>
      </div>


      <!-- address form portion -->
      <div class="row" formGroupName="profile">

        <div class="col-6">
          <div class="form-group">
            <label for="addText" class="regLbl">Organization Name</label>
            <input type="text" id="orgName" [ngClass]="{'form-control': true,
                    'is-invalid': !organization_name.valid && organization_name.touched,
                    'is-valid': organization_name.valid}"
              formControlName="organization_name" placeholder="Organization Name" class="fldFmt1 wd-95">
            <small *ngIf="organization_name.errors?.required && organization_name.touched" class="form-text custom-invalid-feedback">Field
              is required</small>
          </div>
        </div>
      </div>
      <div class="row" formGroupName="profile">
        <div class="col-6">
          <div class="form-group">
            <label for="addText" class="regLbl">Street Address</label>
            <input type="text" id="addText" [ngClass]="{'form-control': true,
                'is-invalid': !streetAddress.valid && streetAddress.touched,
                'is-valid': streetAddress.valid}"
              formControlName="streetAddress" placeholder="Street" class="fldFmt1 wd-95">
            <small *ngIf="streetAddress.errors?.required && streetAddress.touched" class="form-text custom-invalid-feedback">Field
              is required</small>
          </div>
        </div>
        <div class="col">
            <div class="form-group">
              <label for="city" class="regLbl">City</label>
              <input type="text" [ngClass]="{'form-control': true,
              'is-invalid': !city.valid && city.touched,
              'is-valid': city.valid}"
                formControlName="city" id="city" placeholder="City"
                class="fldFmt1 wd-95">
              <small *ngIf="city.errors?.required && city.touched" class="form-text custom-invalid-feedback">Field
                is required</small>
            </div>
          </div>
        <div class="col-12">
          <div class="row">

            <div class="col">
              <div class="form-group">
                <label for="state" class="regLbl">State</label>
                <input type="text" formControlName="state" [ngClass]="{'form-control': true,
                'is-invalid': !state.valid && state.touched,
                'is-valid': state.valid}"
                  id="state" placeholder="State" class="fldFmt1 wd-95">
                <small *ngIf="state.errors?.required && state.touched" class="form-text custom-invalid-feedback">Field
                  is required</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="country" class="regLbl">Country</label>
                <input type="text" formControlName="country" [ngClass]="{'form-control': true,
                'is-invalid': !country.valid && country.touched,
                'is-valid': country.valid}"
                  id="country" placeholder="Country" class="fldFmt1 wd-95">
                <small *ngIf="country.errors?.required && country.touched" class="form-text custom-invalid-feedback">Field
                  is required</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="zipcode" class="regLbl">Zip Code</label>
                <input type="text" formControlName="zipcode" [ngClass]="{'form-control': true,
                'is-invalid': !zipcode.valid && zipcode.touched,
                'is-valid': zipcode.valid}"
                  id="zipcode" placeholder="Zip Code" class="fldFmt1 wd-95">
                <small *ngIf="zipcode.errors?.required && zipcode.touched" class="form-text custom-invalid-feedback">Field
                  is required</small>
                <small *ngIf="zipcode.errors?.pattern && zipcode.touched" class="form-text custom-invalid-feedback">Value
                  should be 5 digit number</small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- seperator -->
      <!-- <div class="row">
        <div class="col">
          <hr>
        </div>
      </div> -->

      <div class="row termCondCls">
          <mat-checkbox><span class="agreeTermFnt">I agree to Melodale's 
              <b class="agreeTermFntClr">Terms & Conditions</b> and 
              <b class="agreeTermFntClr">Quality Policies</b></span></mat-checkbox>
          
      </div>
      <!-- final action buttons -->
      <div class="row submitBtn justify-content-center">

          <div class="col-2">
              <button type="button" [disabled]="!reactiveForm.valid" (click)="submitHandler()" routerLink="/successregister"
                class="btn regBtn">Register Now</button>
            </div>        
          <div class="col-2">
              <button type="button" routerLink="/home"
                class="btn regCancBtn">Cancel</button>
            </div>        

      </div>
    </div>
  </form>
  </div>
</div>
</div>