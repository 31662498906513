<!-- 
    @File       :   app.component.html
    @Time       :   2020/05/12  09:22:10
    @Author     :   Supriya Suresh
    @Version    :   1.0
    @Contact    :   supriya@melodale.com
    @License    :   (c)Copyright 2020-2021, Melodale
    @Desc       :   None 
-->

<div style="text-align:center;">
  <app-main-nav>
  <!-- <app-main-nav [deviceXs]="deviceXs"> -->
    <!-- [deviceSm]="deviceSm" [deviceItsm]="deviceItsm" [deviceLg]="deviceLg"> -->
  </app-main-nav>
</div>

<router-outlet (activate)="onActivate($event)"></router-outlet>
<app-footer></app-footer>