
    <h1>
        PRODUCT PAGE UPDATING SOON!!!!!!
    </h1>
    <h2>
        All Business management students are welcome to use our free version product and grab many ideas about the fundamental project management,
         Resourcemanagement and finance management  and more....!!!
<ul>
         Our product is flexible to use in any,
         <li>
          1.servicing industry work with agile methododlogy, waterfall,Hybrid or traditional management.
        </li>
        <li>
          2.New Product Development industry with R&D.
        </li>
        <li>
           3. Manufacturing Industries.
        </li>
        </ul>
     </h2>

