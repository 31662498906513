import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import { Subscription } from 'rxjs'
import {MatSidenavModule} from '@angular/material/sidenav';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss',
              './../app.component.scss'],
})
export class MainNavComponent {
  // @Input() deviceXs: boolean;
  // @Input() deviceSm: boolean;
  // @Input() deviceItsm: boolean;  
  // @Input() deviceLg: boolean;
  // @Input() name: string;
  isShown:boolean=false;
  
  isOpen = false;
  isDropdownOpen = false;
  showMenu1 = false;
  showMenu2 = false;


  toggleMenu1() {
     this.showMenu1 = !this.showMenu1;
  }
  toggleMenu2() {
    this.showMenu2 = !this.showMenu2;
 }
 onActivate(event) {
  let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
          window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
          window.clearInterval(scrollToTop);
      }
  }, 3);
}
  // @ViewChild(MatMenuTrigger, {static: false}) trigger: MatMenuTrigger;
  // recheckIfInMenu: boolean;

  //***********************NOT WORKING****************************** */
  // toggleNavbar() {
  //   this.isOpen = !this.isOpen;
  // }

  // toggleDropDown() {
  //   this.isDropdownOpen = !this.isDropdownOpen;
  // }
  //***********************NOT WORKING****************************** */

  // openResourceMenu() {
  //   this.trigger.openMenu();
  // }

  // closeResourceMenu() {
  //   setTimeout(() => {
  //     if (this.recheckIfInMenu === false) {
  //       this.trigger.closeMenu();
  //     }
  //   }, 175);
  // }

}




