 
    // @File       :   price.component.ts
    // @Time       :   2020/05/12  09:22:10
    // @Author     :   Supriya Suresh
    // @Version    :   1.0
    // @Contact    :   supriya@melodale.com
    // @License    :   (c)Copyright 2020-2021, Melodale
    // @Desc       :   None 


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss',
            './../app.component.scss'],
})
export class PriceComponent implements OnInit {
 
  private response =[];
  int = 0;
  constructor() { }

  ngOnInit() {
  //PRICING DETAIL JSON   
    this.response = [{
      "pricingList": {
        "freqList": ["Monthly","Half Yearly","Yearly"],
        "pricingNames": ["Student","Premium","SME","Enterprise"],
        "backgroundColor": ["coral","gold","springgreen","mediumturquoise"],
        "Monthly": [{
          "studentPrice": 20,
          "premiumPrice": 35,
          "smePrice": 50,
          "enterprisePrice": 75,
          "frequency": "Monthly"
        }],
        "HalfYearly": [{
          "studentPrice": 120,
          "premiumPrice": 210,
          "smePrice": 300,
          "enterprisePrice": 450,
          "frequency": "Half Yearly"
        }],
        "Yearly": [{
          "studentPrice": 235,
          "premiumPrice": 415,
          "smePrice": 590,
          "enterprisePrice": 890,
          "frequency": "Yearly"
        }],
        "feature": [{
					"label": [
						"Core Features",
						"10 Major Report Access",
						"Project Data Access",
						"Mail Notification",
						"Custom Domain",
            "Unlimited Support"
          ]},
				{
					"label": [
						"Core Features",
						"10 Major Report Access",
						"Project Data Access",
						"Mail Notification",
						"Custom Domain",
						"Unlimited Support"
					]},
				{
					"label": [
						"Core Features",
						"Project Data Access",
						"Mail Notification",
						"Custom Domain",
            "Unlimited Support",
            "Advanced Control",
            "Form Builder"
					]},
				{
					"label": [
						"Core Features",
						"Mail Notification",
						"Custom Domain",
            "Advanced Control",
            "Form Builder",
            "User Registration",
            "Business Features"
					]}
			]}
    }];
    
    this.loadPrice();
  }

  loadPrice() {
    this.onPrice(0);
    this.getPricingNames();
    this.getFreqList();
  }
  getPricingNames() {
    return this.response[0].pricingList.pricingNames;
  }
  getPackage(n: number) {
    return this.response[0].pricingList.pricingNames[n];
  }
  getFreqList() {
    return this.response[0].pricingList.freqList;
  }
  getFeatureList(k: number) {
    return this.response[0].pricingList.feature[k].label;
  }
  getBackgroundColor(j: number) {
    return this.response[0].pricingList.backgroundColor[j];
  }
  onPrice(i: number) {
    this.int = i;
    this.getStudentPrice(i);
    this.getPremiumPrice(i);
    this.getSmePrice(i);
    this.getEnterprisePrice(i);
    this.getFreq(i);
  }

  getStudentPrice(s: number) {
    if(s==0) {
      return this.response[0].pricingList.Monthly[0].studentPrice;
    }
    else if(s==1) {
      return this.response[0].pricingList.HalfYearly[0].studentPrice;
    }
    else if (s==2) {
      return this.response[0].pricingList.Yearly[0].studentPrice;
    }  
  }
  getPremiumPrice(p: number) {
    if(p==0) {
      return this.response[0].pricingList.Monthly[0].premiumPrice;
    }
    else if(p==1) {
      return this.response[0].pricingList.HalfYearly[0].premiumPrice;
    }
    else if (p==2) {
      return this.response[0].pricingList.Yearly[0].premiumPrice;
    }  
  }
  getSmePrice(m: number) {
    if(m==0) {
      return this.response[0].pricingList.Monthly[0].smePrice;
    }
    else if(m==1) {
      return this.response[0].pricingList.HalfYearly[0].smePrice;
    }
    else if (m==2) {
      return this.response[0].pricingList.Yearly[0].smePrice;
    }  
  }
  getEnterprisePrice(e: number) {
    if(e==0) {
      return this.response[0].pricingList.Monthly[0].enterprisePrice;
    }
    else if(e==1) {
      return this.response[0].pricingList.HalfYearly[0].enterprisePrice;
    }
    else if (e==2) {
      return this.response[0].pricingList.Yearly[0].enterprisePrice;
    }  
  }
  getFreq(f: number) {
    if(f==0) {
      return this.response[0].pricingList.Monthly[0].frequency;
    }
    else if(f==1) {
      return this.response[0].pricingList.HalfYearly[0].frequency;
    }
    else if (f==2) {
      return this.response[0].pricingList.Yearly[0].frequency;
    }  
  }

  //Function Passes data to registration page on selection
  btnHandler(priceSelect:string,freqSelect:string) {
    console.log(priceSelect,freqSelect);
    }
  }
