import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {ProductsComponent } from './products/products.component';
import { WhyusComponent } from './whyus/whyus.component';
import { Owner_Registration_SaverComponent } from "./mainlog/owner_registration_saver/owner_registration_saver.component";
import { PriceComponent } from './price/price.component';
import { SuccessregisterComponent } from './successregister/successregister.component';
import {EmpregistrationComponent} from './empregistration/empregistration.component';
import { OpulenceComponent } from './opulence/opulence.component';
import { ServicesSolnComponent } from './services-soln/services-soln.component';
import { ContactUSComponent } from './contact-us/contact-us.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { OurCompanyComponent } from './our-company/our-company.component';
import { MedicalServicesComponent } from './medical-services/medical-services.component';
import { NewProductDevelopementComponent } from './new-product-developement/new-product-developement.component';
import { AiMLComponent } from './ai-ml/ai-ml.component';
import { IotComponent } from './iot/iot.component';
import { NewsUpdateComponent } from './news-update/news-update.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { AgroticsProductComponent } from './agrotics-product/agrotics-product.component';
import { SmartFenceProductComponent } from './smart-fence-product/smart-fence-product.component';
import { SmartSantizerProductComponent } from './smart-santizer-product/smart-santizer-product.component';
import { ElephantRepellentSystemProductComponent } from './elephant-repellent-system-product/elephant-repellent-system-product.component';


const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full',  },
  { path: 'owner_registration_saver', component: Owner_Registration_SaverComponent },
  
  { path: 'opulence', component: OpulenceComponent,},
  {path:'empregistration',component: EmpregistrationComponent},

// {  path: 'cem',  component: CemComponent},
// {  path: 'daleppm', component: DaleppmComponent},

  { path: 'home', component: HomeComponent, },
  
  { path: 'products', component: ProductsComponent, },
  { path: 'whyus', component: WhyusComponent, },
  { path: 'price', component: PriceComponent, },
  { path: 'successregister', component: SuccessregisterComponent, },
  { path: 'servsoln', component: ServicesSolnComponent, },
  { path: 'contactus', component: ContactUSComponent, },
  { path: 'team', component: OurTeamComponent, },
  { path: 'company', component: OurCompanyComponent, },
  { path: 'medical', component: MedicalServicesComponent, },
  { path: 'newproduct', component: NewProductDevelopementComponent, },
  { path: 'aiml', component: AiMLComponent, },
  { path: 'iot', component: IotComponent, },
  { path: 'news', component: NewsUpdateComponent, },
  { path: 'privacy', component: PrivacyComponent, },
  { path: 'termconditions', component: TermsConditionsComponent, },
  { path: 'aboutus', component: AboutusComponent, },
  { path: 'agrotics', component: AgroticsProductComponent, },
  { path: 'smartfence', component: SmartFenceProductComponent, },
  { path: 'smartsantizer', component: SmartSantizerProductComponent, },
  { path: 'elephantrepellent', component: ElephantRepellentSystemProductComponent, },
   
  ] 
 
  

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
