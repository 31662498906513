import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agrotics-product',
  templateUrl: './agrotics-product.component.html',
  styleUrls: ['./agrotics-product.component.scss',
  './../app.component.scss'],
})
export class AgroticsProductComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
