import { FormControl } from '@angular/forms';


// import { OnInit, Component } from "@angular/core";
// import { User } from '../model/owner.model';
// import { FormBuilder, Validators } from '@angular/forms';
// import { Router } from '@angular/router';
// @Component({
//   selector: 'app-owner_registration_saver',
//   templateUrl: './owner_registration_saver.component.html',
//   styleUrls: ['./owner_registration_saver.component.scss']
// })
// export class Owner_Registration_SaverComponent implements OnInit {
//   owner = new User();
//   form;
//   constructor(private fb: FormBuilder, private myRoute: Router) {
//     this.form = fb.group({
//       email: ['', [Validators.required, Validators.email]],
//       password: ['', Validators.required]
//     });
//   }
//   ngOnInit() {
//   }
//   login(owner: User): void {
//     console.log(owner);
//     if (this.form.valid) {
//       this.myRoute.navigate(["successregister"]);
//     }
//   }
// }


import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { User } from './../model/owner.model'; 
@Component({
  selector: 'app-owner_registration_saver',
  templateUrl: './owner_registration_saver.component.html',
  styleUrls: ['./owner_registration_saver.component.scss']
})
export class Owner_Registration_SaverComponent implements OnInit {


  reactiveForm: FormGroup;
  owner = new User();
  constructor(private _fb: FormBuilder) { }

  ngOnInit() {

    // console.log('Initial Value', this.user);
    this.reactiveForm = this._fb.group({
      first_name:[null, [Validators.required, Validators.minLength(2)]],
      last_name: [null, Validators.required],
      email : [null,[Validators.required,Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
      password: [null,[ Validators.required,  Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}$')]],
      confirmPassword: [null,[Validators.required, ]],
      profile: this._fb.group({
        organization_name: [null, Validators.required],
        streetAddress: [null, Validators.required],
        country: [null, Validators.required],
        city: [null, Validators.required],
        state: [null, Validators.required],
        zipcode: [null, [Validators.required, Validators.pattern('^[0-9]{5,6}$')]]
      }),

    

    });
  }

  

  get zipcode() {
    const temp = <FormGroup>this.reactiveForm.controls.profile;
    return temp.controls.zipcode;
  }

  get state() {
    const temp = <FormGroup>this.reactiveForm.controls.profile;
    return temp.controls.state;
  }

  get city() {
    const temp = <FormGroup>this.reactiveForm.controls.profile;
    return temp.controls.city;
  }
  get country() {
    const temp = <FormGroup>this.reactiveForm.controls.profile;
    return temp.controls.country;
  }

  get streetAddress() {
    const temp = <FormGroup>this.reactiveForm.controls.profile;
    return temp.controls.streetAddress;
  }

  get organization_name() {
    const temp = <FormGroup>this.reactiveForm.controls.profile;
    return temp.controls.organization_name;
  }

  get first_name() {
    return this.reactiveForm.get('first_name');
  }
 get email() {
    return this.reactiveForm.get('email');
  }
  get last_name() {
    return this.reactiveForm.get('last_name');
  }


  get password() {
    return this.reactiveForm.get('password');
  }

  get confirmPassword() {
    return this.reactiveForm.get('confirmPassword');
  }
  submitHandler() {
    console.log(this.reactiveForm);
    // console.log('Final Value', this.user);
  }

}
