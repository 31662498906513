import { Component, OnInit } from '@angular/core';
// import {EmpregistrationService} from 'B:\\melodale\\mdppm\\src\\app\\service\\empregistration.service';
import {RouterModule,Routes, Router, ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-empregistration',
  templateUrl: './empregistration.component.html',
  styleUrls: ['./empregistration.component.scss'],
  // providers :[EmpregistrationService]
})
export class EmpregistrationComponent implements OnInit {
  DJANGO_SERVER = 'http://127.0.0.1:8000';
  imageUrl:string = '/assets/images/test.jpg';
  fileToUpload : File = null;
  
  firstname:string = "";
  lastname:string = "";
  Designation:string ="";
  Employeeid:string = "";
  Email:string = "";
  password : "";
  image:string = null;


  constructor(private router:Router,
    private route:ActivatedRoute,
    // private registerService: EmpregistrationService
) { }

  ngOnInit() { }

      onSelectFile(file: FileList) {
      this.fileToUpload = file.item(0);
      var reader = new FileReader();
      reader.onload = (event:any) => {
        this.imageUrl = event.target.result;
      }
        reader.readAsDataURL(this.fileToUpload);
        console.log(this.imageUrl);
    }



  onSave(){
   
    var data1 = { "Firstname": this.firstname,
    "Lastname": this.lastname,
    "Designation": this.Designation,
    "Email": this.Email,
    "Empid": this.Employeeid,
    "password":this.password,
    "Image": this.imageUrl};
    console.log(JSON.stringify(data1));

    // this.registerService.registerNewUser(data1).toPromise().then (response=> {
    //    console.log(JSON.stringify(data1));
       
    //    this.router.navigate(['./login'])

       
    //   });

  }
}
