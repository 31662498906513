import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, Validator } from '@angular/forms';
@Directive({
  selector: '[appEmailValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true}]
})
export class EmailValidatorDirective implements Validator {
  constructor() { }
  validate(control: AbstractControl) {
    const elementValue = control.value;
    if (elementValue === null || elementValue === undefined || elementValue === '') {
      return {'cus_required' : 'Field is required'};
    }
    const reg = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.]+\.[a-z]{2,4}$/);
    if (!reg.test(elementValue)) {
      return {'cus_pattern' : 'This is not a valid email ID.'};
    }
    return null;
  }
}
