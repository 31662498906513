<!-- 
    @File       :   successregister.component.html
    @Time       :   2020/05/12  09:22:10
    @Author     :   Supriya Suresh
    @Version    :   1.0
    @Contact    :   supriya@melodale.com
    @License    :   (c)Copyright 2020-2021, Melodale
    @Desc       :   None 
-->

<div class="container-fluid sr001">
  <div class="sr002">
    <span class="sr003">REGISTRATION SUCCESS</span>
  </div>

  <div class="sr004">
    <span class="sr005"> <strong> Thank you for getting in touch ! </strong> </span>  
      <span class="sr005">We appreciate you contacting Melodale. One of our 
        colleagues will get back in touch with you soon!
      </span>
      <span class="sr005"> Have a great day !  </span>  
  </div>
</div>