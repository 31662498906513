<!-- 
@File : medical-services.component.html
@Time : 2020/07/04 14:22:10
@Author : Supriya Suresh
@Version: 1.0
@Contact: supriya@melodale.com
@License: (c)Copyright 2020-2021, Melodale
@Desc : None 
-->

<div class="ms0000">
    <div class="ms0001">
        <div class="ms0001a">
            <h1 class="ms0001aa mdc002">Bio-medical Engineering Services</h1>
        </div>
        <div class="ms0001b">
            <img class="ms0001bb" src="../../assets/images/BME1.jpg">
        </div>
    </div>
    <div class="ms0002">
        <h3 class="ms0002a mdfnt00">
            Our Expert and skilled engineers ensure the quality of bio medical equipments and implants 
            development through various validation process with Computer-Aided analysis technologies.
        </h3>
    </div>
    <div class="ms0003">
        <div class="ms0003a">
            <img class="ms0003aa" src="../../assets/images/BME4.jpg">
        </div>
        <div class="ms0003b">
            <h2 class="mdfnt00 mdc003 ms0003ba">Our Medical Services</h2>
            <div class="ms0003bb"><span>Our extensive work experience includes design validation using Static, Dynamic and 
                Fatigue analysis of all variety of medical Equipments and implants Such as Dental, Bone, 
                soft tissue etc...</span>
            </div>            
            <div class="ms0003bb"><span>Melodale's talented design engineers develops creative designs, simulate and validate to improve product 
                design by resolving wide range of engineering problems.</span>
            </div>
        </div>
    </div>
    <div class="ms0004">
        <h2 class="mdc003"><span class="mdfnt00">Medical Equipments</span> analysis includes</h2>
    </div>
</div>