import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-successregister',
  templateUrl: './successregister.component.html',
  styleUrls: ['./successregister.component.scss']
})
export class SuccessregisterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
