<!-- 
    @File       :   home.component.html
    @Time       :   2020/05/20  09:22:10
    @Author     :   Supriya Suresh
    @Version    :   1.0
    @Contact    :   supriya@melodale.com
    @License    :   (c)Copyright 2020-2021, Melodale
    @Desc       :   None 
-->

<div class="mdht00">
    <!-- <div class="hm02 hmflex mdbkImg01"> -->
    <div class="hm02 hmflex mdbkc002">
        <div class="hm03">
            <!-- <span @fade class="hm13 mdfnt00 mdc002"> -->
            <span @fade class="hm13 mdfnt00 mdc003">

                Empowering business<br> through real-time data</span>
        </div>
        <div class="hm04">
            <!-- V1.0 Release changes -->
            <!-- <img class="hm04a" src="/assets/icons/owl.svg" alt="Melodale"> -->
        </div>
    </div>

    <!-- Opulence section -->
    <div class="hm08 mdbkc002">
        <carousel>
            <slide>
                <div class="hm001">
                    <mat-card class="hm0020 mdbkc002">
                        <img mat-card-image class="hmimg20a000"
                            src="assets/images/Agro3.jpg"alt="Agrotics">
                        <mat-card-content>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="hm0020 mdbkc002">
                        <h2 class="hm0020 mdfnt00">Agrotics</h2>
                        <mat-card-content>
                          <span class="hm020ab mdfnt01">Make farming in a smart way with our Agrotics. 
                              Crop maintenance is vital when the water facility is limited. 
                              We assist this essential activity with flow sensors where customers can control the water flow.
                        </span>
                        </mat-card-content>
                                    
                        <div class="hm39a">
                            <a mat-button class="mdc002 hm0020a mdbkImg01" routerLink="/agrotics">
                                Learn More</a>
                            <a mat-button class="mdc002 hm0020a mdbkImg01" routerLink="/contactus">
                                Buy Now</a>
                        </div>
                    </mat-card>
              </div>
            </slide>
            
           <!--  <slide>
                <div class="hm001">
                    <mat-card class="hm0020 mdbkc002">
                        <img mat-card-image class="hmimg20a000"
                            src="assets/images/Fence4.png"alt="Smart Fence">
                        <mat-card-content>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="hm0020 mdbkc002">
                        <h2 class="hm0020 mdfnt00">Smart Fence</h2>
                        <mat-card-content>
                          <span class="hm020ab mdfnt01">
                              Our company provides you the premium protection for your land with the 
                              best surveillance and sensors. 
                        </span>
                        </mat-card-content>
                                    
                        <div class="hm39a">
                            <a mat-button class="mdc002 hm0020a mdbkImg01" routerLink="/smartfence">
                                Learn More</a>
                            <a mat-button class="mdc002 hm0020a mdbkImg01" routerLink="/contactus">
                                Buy Now</a>
                        </div>
                    </mat-card>
              </div>
            </slide> -->
            
            <!-- <slide>
                <div class="hm001">
                    <mat-card class="hm0020 mdbkc002">
                        <img mat-card-image class="hmimg20a000"
                            src="assets/images/repellent.jpg"alt="Elephant Repellent System">
                        <mat-card-content>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="hm0020 mdbkc002">
                        <h2 class="hm0020 mdfnt00">Elephant Repellent System</h2>
                        <mat-card-content>
                          <span class="hm020ab mdfnt01">NEED DETAILS
                        </span>
                        </mat-card-content>
                                    
                        <div class="hm39a">
                            <a mat-button class="mdc002 hm0020a mdbkImg01" routerLink="/elephantrepellent">
                                Learn More</a>
                            <a mat-button class="mdc002 hm0020a mdbkImg01" routerLink="/contactus">
                                Buy Now</a>
                        </div>
                    </mat-card>
              </div>
            </slide> -->
            
            <slide>
                <div class="hm001">
                    <mat-card class="hm0020 mdbkc002">
                        <img mat-card-image class="hmimg20a000"
                            src="assets/images/Smartsanitizer.jpg"alt="Smart Santizer">
                        <mat-card-content>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="hm0020 mdbkc002">
                        <h2 class="hm0020 mdfnt00">Smart Santizer</h2>
                        <mat-card-content>
                          <span class="hm020ab mdfnt01">
                              Our Smart surface Santizer will help clean the entire surface with 
                              our UV technology.
                        </span>
                        </mat-card-content>
                                    
                        <div class="hm39a">
                            <a mat-button class="mdc002 hm0020a mdbkImg01" routerLink="/smartsantizer">
                                Learn More</a>
                            <a mat-button class="mdc002 hm0020a mdbkImg01" routerLink="/contactus">
                                Buy Now</a>
                        </div>
                    </mat-card>
              </div>
            </slide>
            
            <slide>
                <div class="hm001">
                    <mat-card class="hm0020 mdbkc002">
                        <img mat-card-image class="hmimg20a000"
                            src="assets/images/opulence.jpg"alt="Opulence">
                        <mat-card-content>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="hm0020 mdbkc002">
                        <h2 class="hm0020 mdfnt00">Opulence</h2>
                        <mat-card-content>
                          <span class="hm020ab mdfnt01">Robust and ready to use tool for team collaborations.
                              <br>
                              Plan<b> . </b>Prioritize<b> . </b>Track
                        </span>
                        </mat-card-content>
                                    
                        <div class="hm39a">
                            <a mat-button class="mdc002 hm0020a mdbkImg01" routerLink="/contactus">
                                Coming Soon</a>
                        </div>
                    </mat-card>
              </div>
            </slide>

        </carousel>
    </div>

    <!-- Services & IT solution Section -->
    <!-- <div class="hm09 mdht00 mdbkImg01">
        <div class="hm14 hm26 mdfnt00 mdc002">Services and Solutions</div>
        <div class="hm19">
            <mat-card class="hm20 mdbkc002">
                <mat-card-header class="hm20a">
                    <mat-card-title class="hm21a hmc004 mdfnt00 mdc001">IoT</mat-card-title>
                </mat-card-header>
                <img mat-card-image class="hmimg22a000"
                    src="../../assets/icons/AI3.svg" alt="IoT">
                <mat-card-content>
                    <p class="hmc005 mdfnt01 mdc001">
                        We direct you towards best in the industry AI practices
                        and systems that add value to your business ventures and web services.
                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <a mat-button class="mdbkImg01" routerLink="/iot">
                        <span class="mdc002">Learn More</span></a>
                </mat-card-actions>
            </mat-card>
        
        
            <mat-card class="hm20 mdbkc002">
                <mat-card-header class="hm20a">
                    <mat-card-title class="hm21a hmc004 mdfnt00 mdc001">AI & ML</mat-card-title>
                </mat-card-header>
                <img mat-card-image class="hmimg22a000"
                    src="../../assets/icons/ML4.svg"alt="AI & ML">
                <mat-card-content>
                    <p class="hmc005 mdfnt01 mdc001">
                        We adorn a team of experts acclimatized with the intricacies
                        of business intelligence, data protection, access control, applicaion
                        testing and outsourcing.
                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <a mat-button class="mdbkImg01" routerLink="/aiml">
                        <span class="mdc002">Learn More</span></a>
                </mat-card-actions>
            </mat-card> -->
            <!-- Hiding it for first Release - 07232020 -->
            <!-- <div class="hm20 mdbkc002">
                <div class="hm21 hmc004 mdfnt00 mdc001">Bio-medical Engineering Services</div>
                <div class="hm22">
                    <img class="hmimg000" src="../../assets/icons/ML5.svg" alt="Melodale">
                </div>
                <div class="hm23">
                    <span class="hmc005 mdfnt01 mdc001">We are capable of crafting Medical Illustrations
                        regardless of scale and level of sophistication.
                    </span>
                </div>
                <div class="hm24">
                    <a mat-button class="mdbkImg01" routerLink="/medical">
                        <span class="mdc002">Learn More</span></a>
                </div>
            </div>
            <div class="hm20 mdbkc002">
                <div class="hm21 hmc004 mdfnt00 mdc001">New Product Development</div>
                <div class="hm22">
                    <img class="hmimg000" src="../../assets/icons/ML8.svg" alt="Melodale">
                </div>
                <div class="hm23">
                    <span class="hmc005 mdfnt01 mdc001">Our product offerings are focused on
                        improving lives through innovation and critical thinking.
                    </span>
                </div>
                <div class="hm24">
                    <a mat-button class="mdbkImg01" routerLink="/newproduct">
                        <span class="mdc002">Learn More</span></a>
                </div>
            </div> -->
            <!-- <div class="hm40">
                <div class="hm39">
                    <a mat-button class="hm0040 mdc002 mdbkc000" routerLink="/contactus">
                        <span class="hm0040a">Join Today !</span></a>
                </div>
            </div> -->
        <!-- </div>
    </div> -->

    <!-- Testimonial Section -->
    <!-- Hiding it for first Release - 07232020 -->
        <!-- <div class="mdht00 mdbkc002">
        <div class="hm010">
            <span class="hm0029a mdc003">What <span class="mdfnt02">
                    our customers</span> are saying</span>
        </div>
        <div class="hm28">
            <div class="hm29">
                <div class="row hm30">
                    <div class="col hm31">
                        <mat-icon class="hm31a mdc000">format_quote</mat-icon>
                    </div>
                    <div class="col hm32 mdbkc001">
                        <img class="hm0032" src="../../assets/employees/1.png">
                    </div>
                    <div class="col hm37">
                        <div class="hm34 mdfnt00 mdc003">
                            Company Spokeperson
                        </div>
                        <div class="hm34 mdfnt00 mdc003">
                            Manager Director at
                            <span class="hm35 mdc000">Company Pvt Ltd</span>
                        </div>
                    </div>
                </div>
                <div class="hm33 mdfnt01 mdc001">
                    Our users are happier because we can help them without
                    needing to ask for more information. Our users are happier because we
                    can help them without needing to ask for more information.
                </div>
            </div>
            <div class="hm29">
                <div class="row hm30">
                    <div class="col hm31">
                        <mat-icon class="hm31a mdc000">format_quote</mat-icon>
                    </div>
                    <div class="col hm32 mdbkc001">
                        <img class="hm0032" src="../../assets/employees/2.png">
                    </div>
                    <div class="col hm37">
                        <div class="hm34 mdfnt00 mdc003">
                            Company Spokeperson
                        </div>
                        <div class="hm34 mdfnt00 mdc003">
                            Manager Director at
                            <span class="hm35 mdc000">Company Pvt Ltd</span>
                        </div>
                    </div>
                </div>
                <div class="hm33 mdfnt01 mdc001">
                    Our users are happier because we can help them without
                    needing to ask for more information. Our users are happier because we
                    can help them without needing to ask for more information.
                </div>
            </div>
            <div class="hm29">
                <div class="row hm30">
                    <div class="col hm31">
                        <mat-icon class="hm31a mdc000">format_quote</mat-icon>
                    </div>
                    <div class="col hm32 mdbkc001">
                        <img class="hm0032" src="../../assets/employees/3.png">
                    </div>
                    <div class="col hm37">
                        <div class="hm34 mdfnt00 mdc003">
                            Company Spokeperson
                        </div>
                        <div class="hm34 mdfnt00 mdc003">
                            Manager Director at
                            <span class="hm35 mdc000">Company Pvt Ltd</span>
                        </div>
                    </div>
                </div>
                <div class="hm33 mdfnt01 mdc001">
                    Our users are happier because we can help them without
                    needing to ask for more information. Our users are happier because we
                    can help them without needing to ask for more information. them without
                    needing to ask for more information.
                </div>
            </div>
        </div>
    </div> -->

    <!-- Contact US Section -->
    <div class="hm11 mdht00 mdbkImg02">
        <div class="hm38">
            <div class="hm0038a mdfnt01 mdc003">
                <span class="hm00038a mdfnt00">Join Us</span><br>
                Plan, Priotize and Track your works with a single click.
            </div>
            <div class="hm39">
                <a mat-button class="mdc002 mdbkImg01" routerLink="/contactus">
                    Get Started</a>
            </div>
        </div>
    </div>
</div>