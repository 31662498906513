import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, Validator } from '@angular/forms';
@Directive({
  selector: '[appPasswordValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective, multi: true}]
})
export class PasswordValidatorDirective implements Validator {
  constructor() { }
  validate(control: AbstractControl) {
    const elementValue = control.value;
    if (elementValue === null || elementValue === undefined || elementValue === '') {
      return {'cus_required' : 'Field is required'};
    }
    const reg = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}$');
    if (!reg.test(elementValue)) {
      return {'cus_pattern' : 'This is not a valid password, use a capital letter, number and symbol'};
    }
    return null;
  }
}